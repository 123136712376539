/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.badge {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs, 8px);
  padding-block: 5px;
  padding-inline-start: 5px;
  padding-inline-end: var(--spacing-xl, 32px);
  background-color: var(--color-white, #fff);
  inline-size: fit-content;
  border-radius: var(--radius-l, 24px);
  border: 0.5px solid var(--secondary-gray, #DEDEDE);
}
.badge_icon {
  background-color: var(--primary-orange, #FAA31E);
  border-radius: var(--radius-m, 20px);
  padding: var(--spacing-xxs, 4px);
}
.badge_icon .icon {
  display: flex;
  block-size: 20px;
  inline-size: 20px;
}
.badge_title {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.badge.no_border {
  border: none;
  padding-inline: 0;
}

.sub {
  display: flex;
  align-items: center;
  gap: 14px;
}
.sub_title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-gray, #5F6A70);
}
.sub_icon .icon {
  display: flex;
  block-size: 20px;
  inline-size: 20px;
}

.header {
  display: flex;
  align-items: center;
  gap: 14px;
}
.header_title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--primary-orange, #FAA31E);
}
.header_icon .icon {
  display: flex;
  block-size: 20px;
  inline-size: 20px;
}

.info {
  display: flex;
  align-items: center;
  gap: 7px;
}
.info_title {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: var(--primary-gray, #5F6A70);
}
.info_icon .icon {
  display: flex;
  block-size: 22px;
  inline-size: 22px;
}