/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.table_wrapper {
  display: flex;
  flex-direction: column;
  inline-size: 100%;
}
.table_wrapper .filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: var(--spacing-xl, 32px);
}
.table_wrapper .table {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
}
.table_wrapper .table .headers {
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 100%;
  gap: var(--spacing-xs, 8px);
  background-color: var(--secondary-gray, #DEDEDE);
  padding-inline: var(--spacing-m, 16px);
  padding-block: var(--spacing-s, 12px);
}
.table_wrapper .table .headers .header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  inline-size: 100%;
  color: var(--primary-gray, #5F6A70);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}
.table_wrapper .table .content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs, 8px);
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  align-items: center;
  color: var(--primary-gray, #5F6A70);
  overflow-y: scroll;
}
.table_wrapper .table .content .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  inline-size: 100%;
  background: var(--color-white, #fff);
  border: 1px solid var(--secondary-gray, #DEDEDE);
  box-sizing: border-box;
  border-radius: var(--radius-xxs, 4px);
  padding-inline: var(--spacing-s, 12px);
  padding-block: var(--spacing-s, 12px);
}
.table_wrapper .table .content .row .column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  inline-size: 100%;
  /* Seller Column */
  /* Action Column */
}
.table_wrapper .table .content .row .column :global(.input_section) {
  background-color: var(--color-white, #fff);
}
.table_wrapper .table .content .row .column .warehouse_section {
  display: flex;
  align-items: center;
  gap: 5px;
}
.table_wrapper .table .content .row .column .warehouse_section span {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  background-color: var(--primary-green, #37B358);
  padding: 2px 4px;
  border-radius: 4px;
  color: var(--color-white, #fff);
}
.table_wrapper .table .content .row .column .warehouse_section a {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: var(--primary-blue, #2D6DB7);
}
.table_wrapper .table .content .row .column .product_card {
  display: flex;
  align-items: center;
  gap: var(--spacing-s, 12px);
}
.table_wrapper .table .content .row .column .product_card .image img {
  width: 60px;
  height: 60px;
  border-radius: var(--radius-s, 12px);
}
.table_wrapper .table .content .row:hover {
  transition: 0.5s;
  background-color: var(--secondary-orange, #FEEED6);
  color: var(--primary-orange, #FAA31E);
}
.table_wrapper .table .content .row:hover .lowest_price :global(.icon) svg {
  stroke: var(--primary-orange, #FAA31E);
}
.table_wrapper .table .content .row:hover .column .actions button {
  background-color: var(--primary-orange, #FAA31E);
  color: var(--secondary-orange, #FEEED6);
}
.table_wrapper .table.complex {
  gap: 0;
}
.table_wrapper .table.complex .headers {
  background-color: var(--primary-gray, #5F6A70);
  border-top-left-radius: var(--radius-xxs, 4px);
  border-top-right-radius: var(--radius-xxs, 4px);
  min-block-size: 50px;
}
.table_wrapper .table.complex .headers .header {
  justify-content: flex-start;
  text-align: left;
  color: var(--color-white, #fff);
  gap: var(--spacing-xs, 8px);
  font-size: 12px;
}
.table_wrapper .table.complex .content {
  gap: 0;
}
.table_wrapper .table.complex .content p {
  margin: 0;
}
.table_wrapper .table.complex .content .row {
  border-radius: 0;
  border-top: 0;
  gap: var(--spacing-xs, 8px);
}
.table_wrapper .table.complex .content .row .column {
  padding-inline-end: 24px;
  align-items: flex-start;
  text-align: left;
  min-block-size: 56px;
}
.table_wrapper .table.complex .content .row:last-child {
  border-bottom-left-radius: var(--radius-xxs, 4px);
  border-bottom-right-radius: var(--radius-xxs, 4px);
}