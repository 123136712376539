/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.navbar {
  display: flex;
  background-color: var(--primary-gray, #5F6A70);
  justify-content: center;
  inline-size: 100%;
  block-size: 100%;
  max-block-size: 100px;
  padding-inline: var(--spacing-l, 24px);
}
.navbar .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar .left_bar {
  display: flex;
  inline-size: 100%;
  flex: 3;
  justify-self: flex-start;
  justify-content: flex-start;
}

.navbar .right_bar {
  display: flex;
  inline-size: 100%;
  flex: 1;
  justify-self: flex-end;
  justify-content: flex-end;
}

.navbar ul {
  display: flex;
  margin: 0;
  justify-content: center;
  block-size: 72px;
}

.navbar ul li:hover {
  transition: 0.3s ease-in-out;
  background: var(--hover-color, #F0F0F0);
}

.navbar ul li:hover a {
  color: var(--primary-gray, #5F6A70);
}

.navbar ul li:hover path {
  fill: var(--primary-gray, #5F6A70);
  stroke: var(--primary-gray, #5F6A70);
}

.navbar ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  background: transparent;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: var(--color-white, #fff);
}

.navbar ul li.no_hover:hover {
  background: transparent;
}

.navbar ul li.no_hover:hover path {
  fill: white;
  stroke: transparent;
}

.navbar ul li.categories_item {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-gray, #DEDEDE);
  margin: 6px 0;
}

.navbar ul li.categories_item span:first-child {
  inline-size: 18px;
}

.navbar ul li.categories_item span:last-child {
  inline-size: 15px;
}

.navbar ul li.categories_item:hover > div.all_categories_dropdown {
  background-color: red;
}

.navbar ul li a {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: var(--secondary-gray, #DEDEDE);
  text-decoration-line: none;
  gap: var(--spacing-s, 12px);
}

.navbar ul li a.mini :global(.icon) {
  inline-size: 15px;
  block-size: 15px;
}

.navbar.with_category_bar, .navbar.with_category_nav {
  margin-block-end: 0;
}

.navbar.with_category_bar .categories_item, .navbar.with_category_nav .categories_item {
  background-color: var(--color-white, #fff);
}

.navbar.with_category_bar .categories_item a, .navbar.with_category_nav .categories_item a {
  color: var(--primary-gray, #5F6A70) !important;
}

.navbar.with_category_bar .categories_item span > svg > path, .navbar.with_category_nav .categories_item span > svg > path {
  fill: var(--primary-gray, #5F6A70);
  stroke: var(--primary-gray, #5F6A70);
}

.menu {
  display: grid;
  padding-block: 9px;
  top: 0 !important;
  background: var(--hover-color, #F0F0F0);
  border-radius: 0 0 10px 10px;
}

.menu .item {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary-gray, #5F6A70);
  margin: 6px 0;
}

.menu .item:hover {
  background-color: var(--color-white, #fff);
}

.category_bar {
  display: flex;
  block-size: 114px;
  inline-size: 100%;
  justify-content: center;
  align-items: center;
  margin-block-end: var(--spacing-xxl, 48px);
  background: var(--primary-orange, #FAA31E);
}

.category_bar .category_title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  text-align: center;
  color: var(--color-white, #fff);
}

.category_nav {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  inline-size: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-block-end: var(--spacing-xxl, 48px);
  padding-block-end: var(--spacing-l, 24px);
  top: 0 !important;
  background: var(--hover-color, #F0F0F0);
}

.category_nav .item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-s, 12px);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  background-color: white;
  padding-inline: var(--spacing-xs, 8px);
  padding-block: var(--spacing-m, 16px);
  cursor: pointer;
  color: var(--primary-gray, #5F6A70);
}

.category_nav .item:hover {
  transition: 0.3s ease-in-out;
  background-color: #fafafa;
}

.category_nav .item img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.category_nav .item:nth-child(8) {
  border-bottom-left-radius: 20px;
}

.category_nav .item:last-child {
  border-bottom-right-radius: 20px;
}

.menu.columns1 {
  grid-template-columns: auto;
}

.menu.columns2 {
  grid-template-columns: auto auto;
}

.menu.columns3 {
  grid-template-columns: auto auto;
}

.menu.columns7 {
  grid-template-columns: auto auto auto auto auto auto auto;
}

.menu::before {
  top: 0 !important;
}

.menu::after {
  top: 0 !important;
}

.user_info {
  display: flex;
  align-items: center;
  gap: var(--spacing-s, 12px);
}
.user_info .name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 125%;
  width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.user_info .name p {
  margin: 0;
  padding: 0;
}
.user_info .mini :global(.icon) {
  inline-size: 16px;
  block-size: 16px;
}