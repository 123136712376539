/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}

.tab_pane_content {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: var(--spacing-xxs, 4px);
}
.tab_pane_content h5 {
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.tab_pane_content h4 {
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.filter_section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-m, 16px);
}
.filter_section button {
  align-self: flex-end;
}

.tcell_product {
  display: flex;
  align-items: center;
  gap: var(--spacing-l, 24px);
}
.tcell_product_image {
  display: flex;
  align-self: flex-start;
}
.tcell_product_image img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.tcell_product_info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
}
.tcell_product_info_name {
  font-weight: 500;
}
.tcell_product_info_description {
  color: var(--secondary-gray, #DEDEDE);
}
.tcell_product_info_attributes {
  display: flex;
  flex-direction: column;
}
.tcell_product_info_attributes_attribute {
  font-weight: 600;
}

.tcell_order_block {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
}
.tcell_order_block .tcell_order {
  display: flex;
  flex-direction: column;
}
.tcell_order_block .tcell_order_line {
  display: flex;
  flex-direction: column;
}

.tcell_cargo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-l, 24px);
  text-align: center;
}
.tcell_cargo .logo {
  display: flex;
  align-self: center;
  justify-content: center;
}
.tcell_cargo .logo img {
  width: 75%;
  height: auto;
  border-radius: var(--spacing-xxs, 4px);
}
.tcell_cargo .block {
  display: flex;
  flex-direction: column;
}
.tcell_cargo .block .line {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs, 8px);
}
.tcell_cargo .block .line.primary_orange {
  color: var(--primary-orange, #FAA31E);
}
.tcell_cargo .block .line.primary_orange :global(.icon) {
  inline-size: 17px;
  block-size: 17px;
}

.tcell_status {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s, 12px);
}
.tcell_status_row {
  display: flex;
  align-items: center;
  color: var(--primary-orange, #FAA31E);
  gap: var(--spacing-s, 12px);
}
.tcell_status_row :global(.icon) {
  inline-size: 17px;
  block-size: 17px;
}
.tcell_status button {
  padding: var(--spacing-xs, 8px);
  font-size: 13.5px;
  width: 100%;
}