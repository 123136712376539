/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.table_wrapper {
  display: flex;
  flex-direction: column;
}
.table_wrapper .filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: var(--spacing-xl, 32px);
}
.table_wrapper .table {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}

.order_detail_steps {
  flex-direction: row;
  padding-block: var(--spacing-m, 16px);
  padding-inline: var(--spacing-l, 24px);
  border-top: 1px solid var(--secondary-gray, #DEDEDE);
  border-right: 1px solid var(--secondary-gray, #DEDEDE);
  border-left: 1px solid var(--secondary-gray, #DEDEDE);
  border-top-left-radius: var(--radius-xs, 8px);
  border-top-right-radius: var(--radius-xs, 8px);
}

.order_detail {
  display: flex;
  flex-direction: column;
  margin-block-end: var(--spacing-m, 16px);
  border: 1px solid var(--secondary-gray, #DEDEDE);
  border-bottom-left-radius: var(--radius-xs, 8px);
  border-bottom-right-radius: var(--radius-xs, 8px);
}
.order_detail .order_items_table table {
  font-family: Poppins;
  font-size: 13px;
  border-collapse: collapse;
  border-radius: var(--radius-s, 12px);
  width: 100%;
}
.order_detail .order_items_table td, .order_detail .order_items_table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.order_detail .order_items_table th {
  font-size: 14px;
  border: 1px solid #aaaaaa;
  background-color: #dddddd;
}
.order_detail .order_items_table th input {
  accent-color: darkgoldenrod;
}
.order_detail .order_items_table .right_align {
  text-align: right;
}
.order_detail .order_block {
  display: flex;
}
.order_detail .order_block .container {
  display: flex;
  flex: 10;
  flex-direction: column;
  gap: var(--spacing-xxl, 48px);
  padding-block: var(--spacing-l, 24px);
  padding-inline: var(--spacing-l, 24px);
}
.order_detail .order_block .container .warning {
  display: flex;
  align-items: center;
  color: var(--primary-orange, #FAA31E);
  gap: var(--spacing-m, 16px);
  padding-block: var(--spacing-s, 12px);
  padding-inline: var(--spacing-m, 16px);
  background-color: var(--secondary-orange, #FEEED6);
  border: 1.5px solid var(--primary-orange, #FAA31E);
  border-radius: var(--radius-s, 12px);
  inline-size: fit-content;
  font-size: 14px;
}
.order_detail .order_block .container .container_block {
  display: flex;
}
.order_detail .order_block .container .container_block .step {
  flex: 1;
}
.order_detail .order_block .container .container_block .items {
  display: flex;
  flex-direction: column;
  flex: 2.5;
  gap: var(--spacing-xxl, 48px);
  max-block-size: 1050px;
  overflow-y: scroll;
}
.order_detail .order_block .container .container_block .items .item {
  display: flex;
  gap: var(--spacing-s, 12px);
  padding-block: var(--spacing-m, 16px);
  padding-inline: var(--spacing-s, 12px);
}
.order_detail .order_block .container .container_block .items .item .image {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
}
.order_detail .order_block .container .container_block .items .item .image .cancel_button {
  font-family: Poppins;
  color: var(--secondary-orange, #FEEED6);
  background-color: var(--primary-orange, #FAA31E);
  padding-block: 8px;
  padding-inline: 9px;
  border: 1px solid var(--secondary-orange, #FEEED6);
  border-radius: var(--radius-s, 12px);
  font-size: 12px;
  font-weight: 500;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.order_detail .order_block .container .container_block .items .item .image .cancel_button:hover {
  background-color: var(--secondary-orange, #FEEED6);
  color: var(--primary-orange, #FAA31E);
  border-color: var(--primary-orange, #FAA31E);
}
.order_detail .order_block .container .container_block .items .item .image img {
  width: 101px;
  height: 101px;
  border-radius: var(--radius-s, 12px);
}
.order_detail .order_block .container .container_block .items .item_detail {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs, 4px);
  font-size: 14px;
}
.order_detail .order_block .container .container_block .items .item_detail .block {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxs, 2px);
}
.order_detail .order_block .container .container_block .items .item_detail .block .title {
  font-weight: 600;
  color: var(--primary-gray, #5F6A70);
}
.order_detail .order_block .container .container_block .items .item_detail .block .title .clickable {
  cursor: pointer;
  color: var(--primary-blue, #2D6DB7);
}
.order_detail .order_block .container .container_block .items .item_detail .block .description {
  font-size: 13px;
  color: var(--primary-gray, #5F6A70);
}
.order_detail .order_block .container .container_block .items .item_detail .block .order_font_size {
  font-size: 20px;
  color: var(--primary-gray, #5F6A70);
}
.order_detail .order_block .container .container_block .items .item_detail .block .description.orange {
  font-weight: 600;
  color: var(--primary-orange, #FAA31E);
}
.order_detail .order_block .container .container_block .items .item.refunded {
  border: 1px solid var(--primary-red);
  background-color: var(--secondary-red);
  border-radius: var(--radius-s, 12px);
}
.order_detail .order_block .summary_block {
  display: flex;
  flex: 6;
  flex-direction: column;
  border-left: 1px solid var(--secondary-gray, #DEDEDE);
}
.order_detail .order_block .summary_block .cargo {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  padding-inline: var(--spacing-xxl, 48px);
  padding-block: 84px;
  gap: var(--spacing-l, 24px);
  font-size: 15px;
  color: var(--primary-gray, #5F6A70);
}
.order_detail .order_block .summary_block .cargo .cargo_title {
  display: flex;
  align-items: flex-start;
  width: auto;
  gap: var(--spacing-m, 16px);
}
.order_detail .order_block .summary_block .cargo .cargo_title .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.order_detail .order_block .summary_block .cargo .cargo_title span {
  inline-size: 30px !important;
  block-size: 30px !important;
}
.order_detail .order_block .summary_block .cargo .cargo_title .detail {
  display: flex;
  flex-direction: column;
}
.order_detail .order_block .summary_block .cargo .cargo_title .detail p {
  margin: 0;
}
.order_detail .order_block .summary_block .cargo .cargo_title.align_center {
  align-items: center;
}
.order_detail .order_block .summary_block .cargo .cargo_detail_block {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: var(--spacing-xl, 32px);
}
.order_detail .order_block .summary_block .cargo .cargo_detail_block .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.order_detail .order_block .summary_block .cargo .cargo_detail_block span {
  inline-size: fit-content;
  block-size: 30px !important;
}
.order_detail .order_block .summary_block .cargo .cargo_detail_block .cargo_detail {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
}
.order_detail .order_block .summary_block .cargo .cargo_detail_block .cargo_detail .block {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxs, 2px);
}
.order_detail .order_block .summary_block .cargo .cargo_detail_block .cargo_detail .block .title {
  font-weight: 600;
  color: var(--primary-gray, #5F6A70);
}
.order_detail .order_block .summary_block .cargo .cargo_detail_block .cargo_detail .block .title .clickable {
  cursor: pointer;
  color: var(--primary-blue, #2D6DB7);
}
.order_detail .order_block .summary_block .cargo .cargo_detail_block .cargo_detail .block .description {
  font-size: 13px;
  color: var(--primary-gray, #5F6A70);
}
.order_detail .order_block .summary_block .refund_section {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  padding-inline: var(--spacing-l, 24px);
  padding-block: 24px;
  gap: var(--spacing-l, 24px);
  font-size: 15px;
  color: var(--primary-gray, #5F6A70);
}
.order_detail .order_block .summary_block .refund_section .image {
  display: flex;
  inline-size: 100%;
  justify-content: space-between;
}
.order_detail .order_block .summary_block .refund_section .image .reason {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.order_detail .order_block .summary_block .refund_section .image .reason h4 {
  margin: 0;
  padding: 0;
}
.order_detail .order_block .summary_block .refund_section .image .reason span {
  font-size: 14px;
}
.order_detail .order_block .summary_block .refund_section .image img {
  border-radius: var(--radius-s, 12px);
}
.order_detail .order_block .summary_block .refund_section .comment {
  display: flex;
  flex-direction: column;
}
.order_detail .order_block .summary_block .refund_section .comment h4 {
  margin: 0;
  padding: 0;
}
.order_detail .order_block .summary_block .refund_section .comment span {
  font-size: 13.5px;
}
.order_detail .order_block .summary_block .summary {
  display: flex;
  flex: 2;
  flex-grow: 0.5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline-start: var(--spacing-xxxl, 96px);
  padding-block: var(--spacing-m, 16px);
  border-top: 1px solid var(--secondary-gray, #DEDEDE);
  gap: var(--spacing-xs, 8px);
}
.order_detail .order_block .summary_block .summary .block {
  display: flex;
  inline-size: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-xl, 32px);
  font-size: 14px;
  font-weight: 600;
}
.order_detail .order_block .summary_block .summary .block .title {
  display: flex;
  text-align: right;
  justify-content: flex-end;
  color: var(--primary-gray, #5F6A70);
}
.order_detail .order_block .summary_block .summary .block .title .clickable {
  cursor: pointer;
  color: var(--primary-blue, #2D6DB7);
}
.order_detail .order_block .summary_block .summary .block .value {
  display: flex;
  text-align: right;
  justify-content: flex-end;
  min-inline-size: 110px;
  color: var(--primary-orange, #FAA31E);
  padding-inline-end: var(--spacing-m, 16px);
  white-space: nowrap;
}
.order_detail .order_block .summary_block .summary .block .value.red {
  color: var(--primary-red);
}
.order_detail .order_block .summary_block .summary .block .value.green {
  color: var(--primary-green);
}
.order_detail .order_block .summary_block .summary .block .value.gray {
  color: var(--primary-gray);
}
.order_detail .order_block .summary_block .summary .block.sub_line {
  gap: 0;
  margin-block: -2.5px;
}
.order_detail .order_block .summary_block .summary .block.sub_line .title {
  font-size: 12px;
}
.order_detail .order_block .summary_block .summary .block.sub_line .value {
  font-size: 12px;
}
.order_detail .actions_block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: var(--spacing-m, 16px);
  gap: var(--spacing-m, 16px);
  border-top: 1px solid var(--secondary-gray, #DEDEDE);
}