/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.default_card {
  inline-size: 100%;
  background-color: var(--color-white, #fff);
  padding-block: 28px;
  padding-inline: 28px;
  border: 0.5px solid var(--secondary-gray, #DEDEDE);
  border-radius: var(--radius-s, 12px);
}

.card {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  background: var(--color-white, #fff);
}
.card .order_card {
  display: flex;
  align-items: center;
  gap: var(--spacing-l, 24px);
  block-size: 96px;
  padding-inline: var(--spacing-m, 16px);
}
.card .order_card .image img {
  width: 50px;
  height: 50px;
  background-color: var(--primary-gray, #5F6A70);
  border-radius: 100%;
  border: none;
}
.card .order_card .detail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 10;
  gap: var(--spacing-xxxl, 96px);
}
.card .order_card .detail_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: flex-start;
}
.card .order_card .detail_block .no {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12.5px;
  color: var(--primary-gray, #5F6A70);
}
.card .order_card .detail_block .no label {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs, 8px);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.card .order_card .detail_block .no .icon {
  display: flex;
  inline-size: 20px;
  block-size: 20px;
}
.card .order_card .detail_block .date {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary-gray, #5F6A70);
}
.card .order_card .detail_block_line {
  width: 1px;
  height: 30px;
  border: 1px solid var(--secondary-gray, #DEDEDE);
  border-radius: var(--radius-s, 12px);
}
.card .order_card .detail_block_inline {
  display: flex;
  justify-content: center;
  justify-self: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  gap: var(--spacing-xs, 8px);
  color: var(--primary-green, #37B358);
}
.card .order_card .detail_block_inline .icon {
  display: flex;
  inline-size: 20px;
  block-size: 20px;
}
.card .order_card .price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: flex-end;
  flex: 1;
  inline-size: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color: var(--color-black, #000);
}
.card .order_card .price p {
  margin-bottom: 0;
}
.card .order_card .price p.payment_type {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--primary-gray, #5F6A70);
}
.card .order_card .right_section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-self: flex-end;
  flex: 2;
  inline-size: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  gap: var(--spacing-xxxl, 96px);
  color: var(--color-black, #000);
}
.card .order_card .right_section .f600 {
  font-weight: 600;
}
.card .order_card .right_section.flex-5 {
  flex: 5;
}
.card .order_card .right_section.flex-7-5 {
  flex: 7.5;
}
.card .order_card .right_section.flex-10 {
  flex: 10;
}
.card .order_card .right_section.flex-15 {
  flex: 15;
}
.card .order_card .collapse_button {
  display: flex;
  justify-content: flex-end;
}
.card .order_card .collapse_button span {
  inline-size: 32px !important;
  block-size: 32px !important;
}
.card .details {
  display: flex;
  flex-direction: column;
  padding-inline: var(--spacing-m, 16px);
  padding-block-start: var(--spacing-s, 12px);
  padding-block-end: var(--spacing-m, 16px);
}
.card .details.collapsed {
  display: none;
}

.toast {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 4px;
}
.toast p {
  margin-bottom: 0;
}
.toast button {
  width: 100px;
  font-size: 12px;
  height: 20px;
  color: var(--primary-green);
  background-color: var(--secondary-green);
}
.toast button:hover {
  background-color: var(--primary-green);
  color: var(--secondary-green);
}