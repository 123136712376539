/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.tab_view .tabs {
  display: flex;
  flex-wrap: wrap;
}
.tab_view .tabs .tab {
  background-color: var(--body-secondary-background-color, #b7bcc0);
  padding-block-start: 24px;
  padding-block-end: 34px;
  padding-inline: 36px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  color: var(--color-white, #fff);
  border-right: 1px solid var(--color-white, #fff);
}
.tab_view .tabs .tab:last-child {
  border-right: none;
}
.tab_view .tabs .tab.selected {
  color: var(--primary-gray, #5F6A70);
  background-color: var(--color-white, #fff);
  transition: 0.3s ease-in-out;
}
.tab_view .tabs .tab:first-child {
  border-top-left-radius: var(--radius-xs, 8px);
}
.tab_view .tabs .tab:last-child {
  border-top-right-radius: var(--radius-xs, 8px);
}
.tab_view .tab_title_sub {
  padding-block: 30px 10px;
  color: var(--primary-gray, #5F6A70);
  margin-bottom: 10px;
}
.tab_view .main_content {
  position: relative;
  z-index: 2;
  margin-block-start: -10px;
  padding-inline: var(--spacing-m, 16px);
  padding-block: var(--spacing-l, 24px);
  background-color: var(--color-white, #fff);
  border-radius: var(--radius-s, 12px);
  font-size: 14px;
  line-height: 17.5px;
}
.tab_view .link {
  float: right;
  color: var(--primary-orange, #FAA31E);
  font-weight: bold;
  font-size: 18px;
}