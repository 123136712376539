/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.page {
  width: 100%;
}

.navbar {
  display: flex;
  justify-content: center;
  padding-block: var(--spacing-l, 24px);
}

.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-inline: 10%;
  padding-block: var(--spacing-xxl, 48px);
  gap: var(--spacing-xxxl, 96px);
  background-color: var(--primary-orange, #FAA31E);
  height: 100%;
  width: 100%;
  background-image: url("https://dpdepoasset.mncdn.com/media/wysiwyg/magebig/login/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.content .advices {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--spacing-m, 16px);
  padding-block: var(--spacing-m, 16px);
}
.content .advices .advice {
  display: flex;
  gap: var(--spacing-s, 12px);
}
.content .advices .advice .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-white, #fff);
  padding: 0;
  gap: var(--spacing-xxs, 4px);
}
.content .advices .advice .content .title {
  font-size: 21px;
  font-weight: 600;
}
.content .advices .advice .content .description {
  font-size: 14px;
}
.content .login {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5rem 3.5rem;
  margin: 0;
  justify-content: space-between;
  gap: var(--spacing-s, 12px);
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4%;
  box-shadow: 0px 1px 2px 0px var(--secondary-gray, #DEDEDE);
}
.content .login .login_form h1 {
  font-weight: 600;
  margin: 0;
}
.content .login .login_form form {
  display: flex;
  flex-direction: column;
}
.content .login .login_form form label {
  color: var(--color-white, #fff);
  font-size: 16px;
}
.content .login .login_form form input {
  font-family: "Poppins";
}
.content .login .login_form form :global(.input_group) {
  padding-block-start: var(--spacing-l, 24px);
}
.content .login .login_form form :global(.input_group) label {
  font-size: 18px;
}
.content .login .login_form form :global(.input_section) {
  background-color: var(--color-white, #fff);
  color: var(--secondary-orange, #FEEED6);
}
.content .login .login_form form .forgot_password {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-block: var(--spacing-xs, 8px);
}
.content .login .login_form form .forgot_password a {
  color: var(--primary-blue, #2D6DB7);
  font-size: 16px;
}
.content .login .login_form form .forgot_password a:hover {
  text-decoration: underline;
}
.content .login .login_form form .actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
  gap: var(--spacing-m, 16px);
  padding-block-start: var(--spacing-xxl, 48px);
}
.content .login .forgot_password_form h1 {
  display: flex;
  align-items: center;
  gap: var(--spacing-m, 16px);
  font-weight: 600;
  margin: 0;
}
.content .login .forgot_password_form h4 {
  padding-block-end: var(--spacing-m, 16px);
}
.content .login .forgot_password_form form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}
.content .login .forgot_password_form form label {
  color: var(--color-white, #fff);
  font-size: 16px;
}
.content .login .forgot_password_form form input {
  font-family: "Poppins";
}
.content .login .forgot_password_form form :global(.input_group) label {
  font-size: 18px;
}
.content .login .forgot_password_form form :global(.input_section) {
  background-color: var(--color-white, #fff);
  color: var(--secondary-orange, #FEEED6);
}
.content .login .forgot_password_form form .forgot_password {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-block: var(--spacing-xs, 8px);
}
.content .login .forgot_password_form form .forgot_password a {
  color: var(--primary-blue, #2D6DB7);
  font-size: 16px;
}
.content .login .forgot_password_form form .forgot_password a:hover {
  text-decoration: underline;
}
.content .login .forgot_password_form form .actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
  gap: var(--spacing-m, 16px);
  padding-block-start: var(--spacing-xxl, 48px);
}
.content .login .forgot_password_form label.verification_label {
  color: var(--color-black, #000);
  font-size: 14.5px !important;
  font-weight: 400;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}
.footer_links {
  display: flex;
  list-style: none;
  text-align: center;
  gap: var(--spacing-l, 24px);
  padding-block-start: var(--spacing-l, 24px);
}
.footer_links li {
  padding-inline-end: var(--spacing-m, 16px);
  border-right: 1px solid var(--primary-gray, #5F6A70);
}
.footer_links li:last-child {
  border-right: none;
  padding-inline-end: unset;
}
.footer_links li a {
  color: var(--primary-gray, #5F6A70);
  font-size: 15px;
  transition: transform 0.2s !important;
}
.footer_links li a:hover {
  transform: scale(1.5) !important;
  color: var(--primary-blue, #2D6DB7);
}
.footer_copyright {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-block: var(--spacing-l, 24px);
  padding-inline: var(--spacing-xxxl, 96px);
  width: 100%;
  border-top: 1px solid var(--secondary-gray, #DEDEDE);
}
.footer_copyright_text {
  display: flex;
}
.footer_copyright_sponsors {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-s, 12px);
  align-self: flex-start;
}