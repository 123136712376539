/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}

.tab_pane_content {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: var(--spacing-xxs, 4px);
}
.tab_pane_content h5 {
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.tab_pane_content h4 {
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.filter_section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-m, 16px);
}
.filter_section button {
  align-self: flex-end;
}

.table_wrapper {
  display: flex;
  flex-direction: column;
}
.table_wrapper .filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: var(--spacing-xl, 32px);
}
.table_wrapper .table {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}

.order_detail {
  display: flex;
  flex-direction: column;
  margin-block-end: var(--spacing-m, 16px);
  border: 1px solid var(--secondary-gray, #DEDEDE);
  border-radius: var(--radius-xs, 8px);
}
.order_detail .order_block {
  display: flex;
}
.order_detail .order_block .container {
  display: flex;
  flex: 2.5;
  flex-direction: column;
  gap: var(--spacing-xxl, 48px);
  padding-block: var(--spacing-xxl, 48px);
  padding-inline: var(--spacing-xxl, 48px);
}
.order_detail .order_block .container .warning {
  display: flex;
  align-items: center;
  color: var(--primary-orange, #FAA31E);
  gap: var(--spacing-m, 16px);
  padding-block: var(--spacing-s, 12px);
  padding-inline: var(--spacing-m, 16px);
  background-color: var(--secondary-orange, #FEEED6);
  border: 1.5px solid var(--primary-orange, #FAA31E);
  border-radius: var(--radius-s, 12px);
  inline-size: fit-content;
  font-size: 14px;
}
.order_detail .order_block .container .container_block {
  display: flex;
}
.order_detail .order_block .container .container_block .step {
  flex: 1;
}
.order_detail .order_block .container .container_block .items {
  display: flex;
  flex-direction: column;
  flex: 2.5;
  gap: var(--spacing-xxl, 48px);
}
.order_detail .order_block .container .container_block .items .item {
  display: flex;
  gap: var(--spacing-s, 12px);
}
.order_detail .order_block .container .container_block .items .item .image img {
  width: 101px;
  height: 101px;
  border-radius: var(--radius-s, 12px);
}
.order_detail .order_block .container .container_block .items .item_detail {
  display: flex;
  flex-direction: column;
  gap: 14px;
  font-size: 14px;
}
.order_detail .order_block .container .container_block .items .item_detail .block {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxs, 2px);
}
.order_detail .order_block .container .container_block .items .item_detail .block .title {
  font-weight: 600;
  color: var(--primary-gray, #5F6A70);
}
.order_detail .order_block .container .container_block .items .item_detail .block .description {
  font-size: 13px;
  color: var(--primary-gray, #5F6A70);
}
.order_detail .order_block .container .container_block .items .item_detail .block .description.orange {
  font-weight: 600;
  color: var(--primary-orange, #FAA31E);
}
.order_detail .order_block .summary_block {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-left: 1px solid var(--secondary-gray, #DEDEDE);
}
.order_detail .order_block .summary_block .cargo {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  padding-inline: var(--spacing-xxl, 48px);
  padding-block: 84px;
  gap: var(--spacing-l, 24px);
  font-size: 15px;
  color: var(--primary-gray, #5F6A70);
}
.order_detail .order_block .summary_block .cargo .cargo_title {
  display: flex;
  align-items: center;
  gap: var(--spacing-m, 16px);
}
.order_detail .order_block .summary_block .cargo .cargo_title span {
  inline-size: 30px !important;
  block-size: 30px !important;
}
.order_detail .order_block .summary_block .cargo .cargo_detail {
  display: flex;
  flex-direction: column;
  padding-inline-start: var(--spacing-xxl, 48px);
  gap: var(--spacing-m, 16px);
}
.order_detail .order_block .summary_block .cargo .cargo_detail .block {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxs, 2px);
}
.order_detail .order_block .summary_block .cargo .cargo_detail .block .title {
  font-weight: 600;
  color: var(--primary-gray, #5F6A70);
}
.order_detail .order_block .summary_block .cargo .cargo_detail .block .description {
  font-size: 13px;
  color: var(--primary-gray, #5F6A70);
}
.order_detail .order_block .summary_block .summary {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline-start: var(--spacing-xxxl, 96px);
  border-top: 1px solid var(--secondary-gray, #DEDEDE);
  gap: 6px;
}
.order_detail .order_block .summary_block .summary .block {
  display: flex;
  inline-size: 100%;
  align-items: center;
  gap: var(--spacing-xl, 32px);
  font-size: 14px;
  font-weight: 600;
}
.order_detail .order_block .summary_block .summary .block .title {
  display: flex;
  flex: 2;
  text-align: right;
  justify-content: flex-end;
  color: var(--primary-gray, #5F6A70);
  white-space: nowrap;
}
.order_detail .order_block .summary_block .summary .block .value {
  display: flex;
  flex: 1;
  text-align: left;
  justify-content: flex-start;
  color: var(--primary-orange, #FAA31E);
}
.order_detail .order_block .summary_block .summary .block.highlighted {
  border-bottom: 0.5px solid var(--primary-gray);
  padding-block-end: 6px;
}
.order_detail .order_block .summary_block .summary .block.bordered {
  border: 5px solid var(--primary-gray);
  border-radius: 6px;
  padding-block: 6px;
}
.order_detail .actions_block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: var(--spacing-m, 16px);
  gap: var(--spacing-m, 16px);
  border-top: 1px solid var(--secondary-gray, #DEDEDE);
}

.approve_modal_content {
  display: flex;
  flex-direction: column;
  padding-inline: var(--spacing-xl, 32px);
  padding-block: var(--spacing-xl, 32px);
  gap: var(--spacing-xxs, 4px);
}
.approve_modal_content .title {
  font-weight: 600;
  font-size: 15px;
}
.approve_modal_content .description {
  font-size: 13px;
}