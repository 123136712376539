/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}
.container .section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl, 32px);
}
.container .section .block {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
}
.container .section .cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  gap: var(--spacing-l, 24px);
}
.container .section .cards :global(.default_card) {
  min-block-size: 170px;
}
.container .section .cards img {
  margin-block-end: 10px;
}
.container .section .cards h1, .container .section .cards h2, .container .section .cards h3, .container .section .cards h4, .container .section .cards h5, .container .section .cards h6 {
  display: flex;
  align-items: center;
}
.container .section .cards h3 {
  font-weight: 600;
}
.container .section .cards h3.amount {
  margin-block-end: 20px;
}
.container .section .cards h5 {
  font-weight: 600;
  gap: var(--spacing-xxs, 4px);
  color: var(--primary-gray, #5F6A70);
}
.container .section .cards h5 :global(.icon) {
  inline-size: 18px;
  block-size: 18px;
}
.container .section .cards .container_with_icon {
  display: flex;
  flex-direction: column;
}
.container .section .cards .container_with_icon :global(.default_card) {
  display: flex;
  align-items: center;
  min-block-size: auto;
}
.container .section .cards .container_with_icon_block {
  display: flex;
  align-items: center;
  gap: var(--spacing-s, 12px);
}
.container .section .cards .container_with_icon_block img {
  margin-block-end: 0;
}
.container .section .cards .container_with_icon_block .container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs, 8px);
}
.container .section .cards .container_with_icon_block .container .questions {
  display: flex;
  gap: var(--spacing-m, 16px);
  align-items: center;
}
.container .section .cards .container_with_icon_block .container .questions span {
  color: var(--primary-gray, #5F6A70);
  font-size: 14px;
  margin-bottom: 0;
}
.container .section .cards .container_with_icon_block .container p {
  color: var(--primary-gray, #5F6A70);
  font-size: 14px;
  margin-bottom: 0;
}
.container .section .cards .container_with_icon_block .container h5 {
  font-weight: 600;
  gap: var(--spacing-xxs, 4px);
  color: var(--primary-gray, #5F6A70);
  padding: 0;
  margin: 0;
}
.container .section .cards .container_with_icon_block .container h5 :global(.icon) {
  inline-size: 18px;
  block-size: 18px;
}
.container .section .cards .requests {
  display: flex;
  flex-direction: column;
}
.container .section .cards .requests_block {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
}
.container .section .cards .requests_block_container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}
.container .section .cards .requests_block_container .request {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs, 8px);
}
.container .section .cards .requests_block_container .request p {
  color: var(--primary-gray, #5F6A70);
  font-size: 14px;
  margin-bottom: 0;
}
.container .section .cards .requests_block_container .request h5 {
  color: var(--primary-orange, #FAA31E);
  font-weight: 600;
  gap: var(--spacing-xxs, 4px);
  padding: 0;
  margin: 0;
}
.container .section .cards .requests_block_container .request h5 :global(.icon) {
  inline-size: 18px;
  block-size: 18px;
}
.container .section .cards .requests_block_container .request span.date {
  color: var(--primary-gray, #5F6A70);
  font-size: 12px;
  margin-bottom: 0;
}
.container .section .cards.column {
  display: flex;
  flex-direction: column;
}
.container .section .big_card {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
}
.container .section .big_card h5 {
  font-weight: 600;
  gap: var(--spacing-xxs, 4px);
  color: var(--primary-gray, #5F6A70);
}
.container .section .big_card h5 :global(.icon) {
  inline-size: 18px;
  block-size: 18px;
}
.container .section .big_card.progress :global(.default_card) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-s, 12px);
  min-block-size: 375px;
}
.container .section .big_card.history :global(.default_card) {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s, 12px);
  min-block-size: 375px;
}
.container .section .payment_progress {
  display: flex;
  align-items: center;
}
.container .section .payment_progress_block {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container .section .payment_progress_block .logo {
  display: flex;
  align-items: center;
  padding-block-end: var(--spacing-l, 24px);
}
.container .section .payment_progress_block .logo :global(.icon) {
  inline-size: 52px;
  block-size: 52px;
}
.container .section .payment_progress_block h1, .container .section .payment_progress_block h2, .container .section .payment_progress_block h3, .container .section .payment_progress_block h4, .container .section .payment_progress_block h5, .container .section .payment_progress_block h6 {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.container .section .payment_progress_block h3 {
  font-weight: 600;
  margin-block-start: var(--spacing-m, 16px);
  font-size: 20px;
}
.container .section .payment_progress_block h5 {
  font-weight: 600;
  color: var(--primary-gray, #5F6A70);
  font-size: 15px;
}
.container .section .payment_progress_block h6 {
  font-size: 14px;
  color: var(--primary-gray, #5F6A70);
  font-weight: 500;
}
.container .section .payment_history {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}
.container .section .payment_history .header {
  display: flex;
  justify-content: space-between;
}
.container .section .payment_history .header .link {
  color: var(--primary-orange, #FAA31E);
}
.container .section .payment_history .header .link:hover {
  cursor: pointer;
  text-decoration-line: underline;
}
.container .section .payment_history .options {
  display: flex;
  align-items: center;
  gap: var(--spacing-m, 16px);
  flex-wrap: wrap;
}
.container .section .payment_history .options button {
  block-size: 48px;
  inline-size: 160px;
}
.container .section .payment_history .info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs, 8px);
}
.container .section .payment_history .info .price {
  font-weight: 600;
  font-size: 21px;
}
.container .section .payment_history .info .description {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs, 8px);
}
.container .section .payment_history .summary {
  display: flex;
  justify-content: space-around;
  align-items: center;
  inline-size: 100%;
  margin-block-start: var(--spacing-l, 24px);
  text-align: center;
  padding-block: var(--spacing-m, 16px);
  padding-inline: var(--spacing-s, 12px);
  border: 1px solid var(--secondary-gray, #DEDEDE);
  border-radius: var(--radius-s, 12px);
}
.container .section .payment_history .summary_block {
  display: flex;
  flex-direction: column;
}
.container .section .payment_history .summary_block_title {
  font-weight: 600;
  font-size: 15px;
  color: var(--primary-gray, #5F6A70);
}
.container .section .payment_history .summary_block_value {
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-orange, #FAA31E);
}
.container .section .payment_header {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.container .section .payment_header .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container .section .payment_header .header h1, .container .section .payment_header .header h2, .container .section .payment_header .header h3, .container .section .payment_header .header h4, .container .section .payment_header .header h5 {
  margin-bottom: 0;
}
.container .section .payment_header .header .link {
  color: var(--primary-orange, #FAA31E);
}
.container .section .payment_header .header .link:hover {
  cursor: pointer;
  text-decoration-line: underline;
}
.container .section .payment_header .header h3 {
  color: var(--primary-gray, #5F6A70);
}
.container .section .payment_header .header.info {
  justify-content: flex-start;
  gap: var(--spacing-xs, 8px);
  color: var(--text-secondary-gray, #95a2b4);
}
.container .section .payment_header .header.info h1, .container .section .payment_header .header.info h2, .container .section .payment_header .header.info h3, .container .section .payment_header .header.info h4, .container .section .payment_header .header.info h5 {
  color: var(--text-secondary-gray, #95a2b4);
}
.container .section .payment_header .header.info h1 b, .container .section .payment_header .header.info h2 b, .container .section .payment_header .header.info h3 b, .container .section .payment_header .header.info h4 b, .container .section .payment_header .header.info h5 b {
  color: var(--primary-gray, #5F6A70);
}
.container .section .payment_header .options {
  display: flex;
  align-items: center;
  gap: var(--spacing-m, 16px);
  flex-wrap: wrap;
}
.container .section .payment_header .options button {
  block-size: 48px;
  inline-size: 160px;
}
.container .section .payment_header .info {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xl, 32px);
}
.container .section .payment_header .info .price {
  font-weight: 700;
  font-size: 24px;
  color: var(--primary-green, #37B358);
}
.container .section .payment_header .info .description {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: var(--spacing-xs, 8px);
  color: var(--text-secondary-gray, #95a2b4);
}
.container .section .payment_header .info .description b {
  color: var(--primary-gray, #5F6A70);
}
.container .section .payment_header .info .description span:global(.icon) {
  margin-right: var(--spacing-xxs, 4px);
}
.container .section .payment_header .info .description span:global(.icon) svg {
  background-color: var(--text-secondary-gray, #95a2b4);
  border-radius: 15px;
}
.container .section .payment_header .info .description span:global(.icon) path {
  stroke: transparent;
  fill: var(--color-white, #fff);
}
.container .section .payment_header .summary {
  display: flex;
  align-items: center;
  inline-size: 100%;
  margin-block-start: var(--spacing-l, 24px);
  text-align: center;
  padding-block: var(--spacing-m, 16px);
}
.container .section .payment_header .summary_block:first-child {
  padding-inline-start: 0;
}
.container .section .payment_header .summary_block:last-child {
  padding-inline-end: 0;
  border-right: none;
}
.container .section .payment_header .summary_block {
  display: flex;
  flex-direction: column;
  padding-inline: var(--spacing-xl, 32px);
}
.container .section .payment_header .summary_block_title {
  display: flex;
  gap: var(--spacing-xxs, 4px);
  font-weight: 600;
  font-size: 15px;
  color: var(--text-secondary-gray, #95a2b4);
}
.container .section .payment_header .summary_block_value {
  font-weight: 600;
  font-size: 14px;
  color: var(--color-black, #000);
  text-align: left;
}
.container .section .payment_header .summary_block_value.green {
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-green, #37B358);
  text-align: left;
}
.container .section .payment_header .summary_block_value.red {
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-red, #E05252);
  text-align: left;
}
.container .section .payment_header .summary_block_value.secondary_gray {
  font-weight: 600;
  font-size: 14px;
  color: var(--text-secondary-gray, #95a2b4);
  text-align: left;
}
.container .section .payment_header .summary_block_line {
  width: 1px;
  height: 30px;
  border: 1px solid var(--secondary-gray, #DEDEDE);
  border-radius: var(--radius-s, 12px);
}
.container .section .search_section {
  display: flex;
  gap: var(--spacing-m, 16px);
  align-items: flex-end;
  inline-size: 75%;
}
.container .section .table_wrapper {
  display: flex;
  flex-direction: column;
}
.container .section .table_wrapper .filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: var(--spacing-xl, 32px);
}
.container .section .table_wrapper .table {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}

.payments {
  display: flex;
  inline-size: 100%;
  gap: var(--spacing-l, 24px);
  background-color: var(--color-white, #fff);
}
.payments_block {
  display: flex;
  align-items: center;
  flex: 1;
  gap: var(--spacing-xxs, 4px);
  padding: var(--spacing-m, 16px);
  border-radius: var(--radius-s, 12px);
  background-color: var(--bg-secondary-gray, #f7f7fa);
  border: 0.5px solid var(--text-secondary-gray, #95a2b4);
}
.payments_block .logo span {
  display: flex;
}
.payments_block .content {
  display: flex;
  flex-direction: column;
}
.payments_block .content .title {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  gap: var(--spacing-xxs, 4px);
  color: var(--text-secondary-gray, #95a2b4);
}
.payments_block .content .description {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: var(--color-black, #000);
}
.payments_block .content.green .description {
  color: var(--primary-green, #37B358);
}
.payments_block .content.red .description {
  color: var(--primary-red, #E05252);
}