/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.page {
  width: 100%;
}

.navbar {
  display: flex;
  justify-content: center;
  padding-block: var(--spacing-l, 24px);
}

.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-inline: 10%;
  padding-block: var(--spacing-xxl, 48px);
  gap: 36px;
  background-color: var(--primary-orange, #FAA31E);
  height: 100%;
  width: 100%;
  background-image: url("https://dpdepoasset.mncdn.com/media/wysiwyg/magebig/login/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.content h1 {
  margin: 0;
  padding: 0;
  color: var(--color-white, #fff);
  font-weight: 700;
}
.content form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.content form .form_group_row {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: var(--spacing-xxxl, 96px);
  padding-block-start: var(--spacing-l, 24px);
}
.content form .form_group_row :global(.input_group) {
  gap: 6px;
}
.content form .form_group_row :global(.input_group) label {
  color: var(--color-white, #fff);
  font-size: 18px;
}
.content form .form_group_row :global(.input_group) :global(.input_section) {
  background-color: var(--color-white, #fff);
  gap: var(--spacing-s, 12px);
}
.content form .form_group_row.checkbox {
  justify-content: flex-start;
  gap: var(--spacing-s, 12px);
}
.content form .form_group_row.checkbox :global(.checkbox_container) {
  flex: unset;
}
.content form .agreements_label {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs, 8px);
  font-size: 16px;
  font-weight: 600;
  color: var(--color-white, #fff);
}
.content form .agreements_label a {
  color: var(--secondary-blue, #F1F6FB);
  font-size: 17px;
  font-weight: 600;
}
.content form .agreements_label a:hover {
  transition: ease-in-out 0.5s;
  color: var(--primary-blue, #2D6DB7);
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}
.footer_links {
  display: flex;
  list-style: none;
  text-align: center;
  gap: var(--spacing-l, 24px);
  padding-block-start: var(--spacing-l, 24px);
}
.footer_links li {
  padding-inline-end: var(--spacing-m, 16px);
  border-right: 1px solid var(--primary-gray, #5F6A70);
}
.footer_links li:last-child {
  border-right: none;
  padding-inline-end: unset;
}
.footer_links li a {
  color: var(--primary-gray, #5F6A70);
  font-size: 15px;
  transition: transform 0.2s !important;
}
.footer_links li a:hover {
  transform: scale(1.5) !important;
  color: var(--primary-blue, #2D6DB7);
}
.footer_copyright {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-block: var(--spacing-l, 24px);
  padding-inline: var(--spacing-xxxl, 96px);
  width: 100%;
  border-top: 1px solid var(--secondary-gray, #DEDEDE);
}
.footer_copyright_text {
  display: flex;
}
.footer_copyright_sponsors {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-s, 12px);
  align-self: flex-start;
}