/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
  font-family: Poppins;
}
.container .badge_with_navigator {
  display: flex;
  align-items: center;
  gap: var(--spacing-s, 12px);
}
.container .badge_with_navigator :global(.badge:first-child) {
  padding-inline-end: var(--spacing-m, 16px);
  cursor: pointer;
}
.container .product_detail {
  display: flex;
  align-items: center;
  inline-size: 100%;
  gap: var(--spacing-xxl, 48px);
}
.container .product_detail .image img {
  width: 101px;
  height: 101px;
  border-radius: var(--radius-s, 12px);
  cursor: pointer;
}
.container .product_detail .detail {
  display: flex;
  flex: 16;
  flex-direction: column;
  inline-size: 100%;
  gap: var(--spacing-xxs, 4px);
  font-size: 15px;
}
.container .product_detail .detail .name {
  font-weight: 600;
  color: var(--primary-gray, #5F6A70);
  font-size: 17px;
}
.container .product_detail .detail .category {
  font-weight: 400;
  color: var(--primary-gray, #5F6A70);
  font-size: 14px;
}
.container .product_detail .detail .ingredient {
  display: flex;
  align-items: center;
  gap: var(--spacing-m, 16px);
  margin-block-start: var(--spacing-s, 12px);
  font-size: 15px;
  font-weight: 600;
  color: var(--primary-gray, #5F6A70);
}
.container .product_detail .detail .ingredient span {
  font-weight: 400;
  color: var(--primary-gray, #5F6A70);
}
.container .product_detail .badge {
  display: flex;
  flex: 12;
  align-items: center;
  justify-content: flex-end;
  inline-size: 100%;
}
.container .product_detail .logo {
  display: flex;
  flex: 4;
  align-items: center;
  justify-content: flex-end;
  inline-size: 100%;
}
.container .option_section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl, 32px);
}
.container .option_section .filter_section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-m, 16px);
}
.container .option_section .filter_section button {
  align-self: flex-end;
}
.container .option_section .select_with_label {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.container .option_section .select_with_label label {
  display: flex;
  gap: var(--spacing-xs, 8px);
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  color: var(--primary-gray, #5F6A70);
}
.container .option_section .options {
  display: flex;
  align-self: flex-end;
  gap: var(--spacing-m, 16px);
}
.container .option_section .options .option {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs, 8px);
  padding-block: var(--spacing-s, 12px);
  padding-inline-start: var(--spacing-l, 24px);
  padding-inline-end: var(--spacing-l, 24px);
  border: 2px solid var(--primary-orange, #FAA31E);
  border-radius: var(--radius-s, 12px);
  transition: 0.3s ease-in-out;
}
.container .option_section .options .option .name {
  display: flex;
  align-items: center;
  gap: var(--spacing-m, 16px);
  font-weight: 600;
  color: var(--primary-gray, #5F6A70);
  font-size: 15px;
}
.container .option_section .options .option .values {
  display: flex;
  align-items: center;
  color: var(--primary-gray, #5F6A70);
  font-size: 14px;
}

.tcell_product {
  display: flex;
  align-items: center;
  gap: var(--spacing-l, 24px);
}
.tcell_product_image img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.tcell_product_info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
}
.tcell_product_info_name {
  font-weight: 500;
}
.tcell_product_info_description {
  display: flex;
  flex-direction: column;
}
.tcell_product_info_description_toggle {
  color: var(--primary-blue, #2D6DB7);
  cursor: pointer;
}
.tcell_product_info_description_content {
  font-size: 10px;
}

.tcell_catalog_number {
  display: flex;
  align-items: center;
  gap: var(--spacing-l, 24px);
  font-size: 10.5px;
}

.tcell_expiration {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--spacing-xs, 8px);
}
.tcell_expiration_switch {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs, 8px);
}

.tcell_lowest_price {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-xs, 8px);
  gap: var(--spacing-xs, 8px);
  white-space: nowrap;
  padding: var(--spacing-xs, 8px);
  box-shadow: 0 0 0 1.5px var(--primary-orange, #FAA31E);
  font-size: 12px;
}

.variants_action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sticky_header {
  top: 0;
  position: sticky;
  z-index: 2;
}