/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  overflow-y: hidden;
  inline-size: 100%;
  block-size: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  user-select: none;
  transition: 250ms opacity, 250ms visibility;
}
.modal ::-webkit-scrollbar {
  width: 12px;
}
.modal ::-webkit-scrollbar-track {
  background: var(--color-white, #fff);
  border-radius: var(--radius-s, 12px);
}
.modal ::-webkit-scrollbar-thumb {
  background: var(--primary-gray, #5F6A70);
  border: 2px solid var(--color-white, #fff);
  border-radius: var(--radius-s, 12px);
}
.modal ::-webkit-scrollbar-thumb:hover {
  background: var(--primary-gray, #5F6A70);
}
.modal:global(.ReactModal__Overlay--after-open) {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.modal:global(.ReactModal__Overlay--before-close) {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.modal_content {
  inline-size: 100%;
  min-inline-size: 350px;
  background-color: #fff;
}
.modal_content .close_icon {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 17px;
  color: var(--primary-gray, #5F6A70);
}
.modal_content .close_icon span > svg > path {
  fill: var(--primary-gray, #5F6A70);
}
.modal_content .close_icon span:hover > svg > path {
  transition: 0.3s;
  fill: var(--primary-orange, #FAA31E);
}
.modal_header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 1px solid var(--primary-gray, #5F6A70);
  padding-block-end: 1rem;
}
.modal_title_block {
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.modal_title_content {
  display: flex;
  flex-direction: column;
}
.modal_close_icon {
  margin-inline-start: 2rem;
}
.modal_close_icon button {
  background-color: transparent;
  border: none;
}
.modal_close_icon span {
  inline-size: 12px;
}
.modal_title {
  font-weight: 600;
  color: var(--primary-gray, #5F6A70);
}
.modal_description {
  line-height: 1.25;
  font-size: 12px;
  color: var(--secondary-gray, #DEDEDE);
}
.modal_body {
  padding-block: 1rem;
  font-size: 14px;
  color: var(--primary-gray, #5F6A70);
}
.modal_body p {
  font-weight: 600;
  margin-block-end: 1rem;
}
.modal_body a {
  color: var(--primary-gray, #5F6A70);
}
.modal_footer {
  display: flex;
  justify-content: space-between;
  margin-block-start: 1rem;
}
.modal_footer button {
  font-size: 16px;
  padding-inline: 1.5rem;
  border-radius: var(--radius-s, 12px);
}

.new_address_modal {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
}
.new_address_modal form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
  padding-inline: var(--spacing-m, 16px);
  padding-block: var(--spacing-xs, 8px);
  overflow-x: hidden;
  block-size: auto;
  max-block-size: 40vw;
  /* Rating Product */
}
.new_address_modal form .title {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: var(--primary-orange, #FAA31E);
}
.new_address_modal form .title .recommended {
  font-weight: 300;
}
.new_address_modal form .description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-block-start: var(--spacing-m, 16px);
  color: var(--primary-gray, #5F6A70);
}
.new_address_modal form .info {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-gray, #5F6A70);
}
.new_address_modal form .radio_group {
  display: flex;
  gap: var(--spacing-l, 24px);
  padding-block-end: var(--spacing-l, 24px);
  border-bottom: 2px solid var(--secondary-gray, #DEDEDE);
}
.new_address_modal form .custom_check {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-gray, #5F6A70);
}
.new_address_modal form .less_gap {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
}
.new_address_modal form .form_input p {
  margin-bottom: 0.3rem;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  color: var(--primary-gray, #5F6A70);
}
.new_address_modal form .form_input .stars {
  display: flex;
  flex-direction: row;
}
.new_address_modal form .form_input input {
  block-size: 46px;
  outline: none;
}
.new_address_modal form .form_input input:not(:placeholder-shown), .new_address_modal form .form_input input.error {
  padding-top: 10px;
  padding-bottom: 10px;
}
.new_address_modal form .form_input_m {
  inline-size: 420px;
}
.new_address_modal form .uploaded_images {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}
.new_address_modal form .uploaded_images img {
  gap: var(--spacing-m, 16px);
  flex: 1 1 50%;
  width: 50px;
}
.new_address_modal form .image_info {
  display: flex;
  gap: var(--spacing-m, 16px);
  align-items: center;
}
.new_address_modal form .image_info .info_icon {
  display: flex;
}
.new_address_modal form .image_info p {
  margin: 0;
}
.new_address_modal form .image_info .info_text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--primary-gray, #5F6A70);
}
.new_address_modal form .rate_products {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}
.new_address_modal form .rate_product {
  display: flex;
  gap: var(--spacing-m, 16px);
  padding: var(--spacing-s, 12px);
  border: 2px solid #DEDEDE;
  box-sizing: border-box;
  border-radius: 16px;
}
.new_address_modal form .rate_product .image {
  display: flex;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  object-fit: cover;
  width: 80px;
  height: 80px;
  min-width: 80px;
  border-radius: 8px;
}
.new_address_modal form .rate_product .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.new_address_modal form .rate_product .content p {
  margin: 0;
}
.new_address_modal form .rate_product .content .buttons {
  display: flex;
  inline-size: 100%;
  gap: var(--spacing-m, 16px);
}
.new_address_modal form .rate_product .content .buttons button {
  block-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  padding-inline: var(--spacing-s, 12px);
}
.new_address_modal form .rate_product .content .buttons button :global(.icon) svg {
  font-size: 2px;
  line-height: 0;
  block-size: 13px !important;
  inline-size: 13px !important;
}
.new_address_modal form .rate_product .content .buttons button:hover :global(.icon) svg path {
  fill: var(--primary-orange, #FAA31E);
}
.new_address_modal form .rate_product .price {
  display: flex;
  align-items: center;
  flex: 2;
  flex-grow: 4;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  white-space: nowrap;
}
.new_address_modal form button {
  inline-size: 100%;
}

.zoom_image_modal img {
  border-radius: var(--radius-s, 12px);
}

.price_analyses_modal {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl, 32px);
}
.price_analyses_modal .product_section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs, 8px);
}
.price_analyses_modal .product_section .title {
  display: flex;
  align-items: center;
  gap: var(--spacing-s, 12px);
}
.price_analyses_modal .product_section .single_seller_text {
  color: var(--primary-green, #37B358);
}
.price_analyses_modal .product_section .not_selling_text {
  color: var(--primary-red, #E05252);
}
.price_analyses_modal .product_section .table .price {
  display: flex;
  align-items: center;
  gap: var(--spacing-m, 16px);
}
.price_analyses_modal .product_section .table :global(.ant-table) :global(.ant-table-cell) {
  padding-block: 10px;
}

.iyzico_agrement_modal iframe {
  width: 100%;
  border: none;
  height: auto;
  min-height: 500px;
}

.iyzico_agreement_modal_footer_section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  inline-size: 100%;
}

.cargo_desi_price_table_modal .line {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  border-radius: var(--radius-s, 12px);
  inline-size: 350px;
}
.cargo_desi_price_table_modal .line .desi {
  flex: 1;
  text-align: right;
  border: 1px solid;
  padding-inline: var(--spacing-m, 16px);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-block: 2px;
}
.cargo_desi_price_table_modal .line .price {
  flex: 1;
  text-align: right;
  border: 1px solid;
  font-size: 18px;
  font-weight: 600;
  padding-inline: var(--spacing-m, 16px);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding-block: 2px;
}

.approve_modal_content {
  display: flex;
  flex-direction: column;
  padding-inline: var(--spacing-xl, 32px);
  padding-block: var(--spacing-xl, 32px);
  gap: var(--spacing-xxs, 4px);
}
.approve_modal_content .title {
  font-weight: 600;
  font-size: 15px;
}
.approve_modal_content .description {
  font-size: 13px;
}

.partial_cancel_modal table {
  font-family: Poppins;
  font-size: 13px;
  border-collapse: collapse;
  border-radius: var(--radius-s, 12px);
  width: 100%;
}
.partial_cancel_modal td, .partial_cancel_modal th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.partial_cancel_modal td .cancel_amount_input, .partial_cancel_modal th .cancel_amount_input {
  width: 100%;
  max-width: 75px;
  padding: 5px;
  border-radius: var(--radius-xxxs, 2px);
  border-width: 1.5px;
}
.partial_cancel_modal th {
  font-size: 14px;
  border: 1px solid #aaaaaa;
  background-color: #dddddd;
}
.partial_cancel_modal th input {
  accent-color: darkgoldenrod;
}
.partial_cancel_modal .right_align {
  text-align: right;
}
.partial_cancel_modal_footer_section {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.partial_cancel_modal_footer_section button {
  padding-block: var(--spacing-s, 12px);
  padding-inline: var(--spacing-m, 16px);
  font-size: 14px;
}

.upload_product_conclusion_modal table {
  font-family: Poppins;
  font-size: 13px;
  border-collapse: collapse;
  border-radius: var(--radius-s, 12px);
  width: 100%;
}
.upload_product_conclusion_modal td, .upload_product_conclusion_modal th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.upload_product_conclusion_modal td span.before, .upload_product_conclusion_modal th span.before {
  background-color: var(--primary-red, #E05252);
  color: var(--secondary-red, #F8D9D9);
  font-weight: 500;
  padding-inline: 4px;
  padding-block: 2px;
  margin-inline: 3px;
  text-decoration: line-through solid var(--secondary-red, #F8D9D9) 1px;
  border-radius: 4px;
  white-space: nowrap;
}
.upload_product_conclusion_modal td span.after, .upload_product_conclusion_modal th span.after {
  background-color: var(--primary-green, #37B358);
  color: var(--secondary-green, #DBF1E1);
  font-weight: 500;
  padding-inline: 4px;
  padding-block: 2px;
  margin-inline: 3px;
  border-radius: 4px;
  white-space: nowrap;
}
.upload_product_conclusion_modal td span.same, .upload_product_conclusion_modal th span.same {
  background-color: var(--primary-orange, #FAA31E);
  color: var(--secondary-orange, #FEEED6);
  font-weight: 500;
  padding-inline: 4px;
  padding-block: 2px;
  margin-inline: 3px;
  border-radius: 4px;
  white-space: nowrap;
}
.upload_product_conclusion_modal td span.info, .upload_product_conclusion_modal th span.info {
  background-color: var(--hover-color, #F0F0F0);
  color: var(--primary-gray, #5F6A70);
  font-weight: 500;
  padding-inline: 4px;
  padding-block: 2px;
  margin-inline: 3px;
  border-radius: 4px;
  white-space: nowrap;
}
.upload_product_conclusion_modal th {
  font-size: 14px;
  border: 1px solid #aaaaaa;
  background-color: #dddddd;
}
.upload_product_conclusion_modal th input {
  accent-color: darkgoldenrod;
}
.upload_product_conclusion_modal .right_align {
  text-align: right;
}
.upload_product_conclusion_modal_footer_section {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.upload_product_conclusion_modal_footer_section button {
  padding-block: var(--spacing-s, 12px);
  padding-inline: var(--spacing-m, 16px);
  font-size: 14px;
}

.upload_product_conclusion_modal_error .error_title {
  color: darkred;
  padding-block-start: var(--spacing-s, 12px);
}
.upload_product_conclusion_modal_error .product_error_title {
  color: var(--primary-red, #E05252);
}
.upload_product_conclusion_modal_error .product_error_description {
  color: var(--primary-gray, #5F6A70);
}

.upload_product_info_modal iframe {
  width: 100%;
  border-radius: var(--radius-s, 12px);
  margin-block: var(--spacing-xs, 8px);
}
.upload_product_info_modal h4 {
  color: var(--primary-gray, #5F6A70);
}
.upload_product_info_modal_action_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 20px;
}

.new_order_cargo_boxes_modal table {
  font-family: Poppins;
  font-size: 13px;
  border-collapse: collapse;
  border-radius: var(--radius-s, 12px);
  width: 100%;
}
.new_order_cargo_boxes_modal td, .new_order_cargo_boxes_modal th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.new_order_cargo_boxes_modal th {
  font-size: 14px;
  border: 1px solid #aaaaaa;
  background-color: #dddddd;
}
.new_order_cargo_boxes_modal th input {
  accent-color: darkgoldenrod;
}
.new_order_cargo_boxes_modal .right_align {
  text-align: right;
}
.new_order_cargo_boxes_modal .boxes {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}
.new_order_cargo_boxes_modal .boxes .box {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs, 8px);
}
.new_order_cargo_boxes_modal .boxes .box_title {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs, 4px);
  font-size: 14px;
  margin: 0;
}
.new_order_cargo_boxes_modal .boxes .box_title span {
  inline-size: 19px;
  block-size: 19px;
}
.new_order_cargo_boxes_modal_footer_section {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.new_order_cargo_boxes_modal_footer_section button {
  padding-block: var(--spacing-s, 12px);
  padding-inline: var(--spacing-m, 16px);
  font-size: 14px;
}