/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: fit-content;
  block-size: fit-content;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: bold;
  padding: var(--spacing-m, 16px) var(--spacing-l, 24px);
  white-space: nowrap;
  border-radius: var(--radius-s, 12px);
  border: none;
  background-color: var(--primary-orange, #FAA31E);
  color: var(--secondary-orange, #FEEED6);
  user-select: none;
  will-change: background-color, cursor;
  touch-action: manipulation;
  transition: all 0.3s;
}
@media screen and (hover: hover) and (pointer: fine) {
  .btn:hover {
    color: var(--primary-orange, #FAA31E);
    background-color: var(--secondary-orange, #FEEED6);
    cursor: pointer;
  }
}
.btn:focus {
  transition: outline-offset 0.2s ease;
  outline-offset: 4px;
}
.btn span {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: inherit;
}
.btn.secondary {
  background-color: var(--secondary-orange, #FEEED6);
  color: var(--primary-orange, #FAA31E);
}
@media screen and (hover: hover) and (pointer: fine) {
  .btn.secondary:hover {
    background-color: var(--primary-orange, #FAA31E);
    color: var(--color-white, #fff);
    cursor: pointer;
  }
}
.btn.primary-green {
  background-color: var(--primary-green, #37B358);
  color: var(--secondary-green, #DBF1E1);
}
@media screen and (hover: hover) and (pointer: fine) {
  .btn.primary-green:hover {
    color: var(--primary-green, #37B358);
    background-color: var(--secondary-green, #DBF1E1);
    cursor: pointer;
  }
}
.btn.primary-gray {
  background-color: var(--primary-gray, #5F6A70);
  color: var(--secondary-gray, #DEDEDE);
}
@media screen and (hover: hover) and (pointer: fine) {
  .btn.primary-gray:hover {
    background-color: var(--secondary-gray, #DEDEDE);
    color: var(--primary-gray, #5F6A70);
    cursor: pointer;
  }
}
.btn.ghost {
  background-color: transparent;
  color: var(--primary-gray, #5F6A70);
}
@media screen and (hover: hover) and (pointer: fine) {
  .btn.ghost:hover {
    transform: translateY(-5px);
    cursor: pointer;
  }
}
.btn.outlined {
  background-color: transparent;
  color: var(--primary-gray, #5F6A70);
  border: 1px solid var(--primary-gray, #5F6A70);
  border-radius: var(--spacing-xl, 32px);
}
@media screen and (hover: hover) and (pointer: fine) {
  .btn.outlined:hover {
    transition: 0.25s;
    border: none;
    color: var(--secondary-gray, #DEDEDE);
    background-color: var(--primary-gray, #5F6A70);
    cursor: pointer;
  }
}
.btn.outlined-primary {
  background-color: transparent;
  color: var(--primary-orange, #FAA31E);
  border: 1px solid var(--primary-orange, #FAA31E);
  border-radius: var(--radius-s, 12px);
}
@media screen and (hover: hover) and (pointer: fine) {
  .btn.outlined-primary:hover {
    transition: 0.25s;
    color: var(--secondary-orange, #FEEED6);
    background-color: var(--primary-orange, #FAA31E);
    cursor: pointer;
  }
}
.btn.option {
  background-color: transparent;
  color: var(--primary-gray, #5F6A70);
  border: 1px solid var(--primary-gray, #5F6A70);
  border-radius: var(--spacing-xl, 32px);
}
@media screen and (hover: hover) and (pointer: fine) {
  .btn.option:hover {
    transition: 0.25s;
    border-color: var(--primary-orange, #FAA31E);
    color: var(--primary-orange, #FAA31E);
    cursor: pointer;
  }
}
.btn.option_selected {
  background-color: transparent;
  color: var(--primary-orange, #FAA31E);
  border: 1px solid var(--primary-orange, #FAA31E);
  border-radius: var(--spacing-xl, 32px);
}
.btn.fullWidth:is(.btn, .secondary, .ghost) {
  inline-size: 100%;
}
.btn.sticky:is(.btn, .secondary, .ghost) {
  border-radius: unset;
}
.btn.just_icon {
  background-color: transparent;
  padding: var(--spacing-xs, 8px);
  margin: 0;
  border: 1px solid var(--secondary-gray, #DEDEDE);
  border-radius: 6px;
}
.btn.just_icon :global(.icon) {
  inline-size: 32px !important;
  block-size: 32px !important;
}
.btn.just_icon :global(.icon) svg {
  inline-size: 32px !important;
  block-size: 32px !important;
}
.btn.withIcon:is(.btn, .secondary, .ghost) :global(.icon) {
  inline-size: 32px;
  block-size: 32px;
  margin-inline-end: var(--spacing-s, 12px);
}
.btn.compact {
  font-weight: 500;
}
.btn.compact:is(.btn, .secondary) {
  padding: 10px 32px;
}
.btn.compact:is(.btn, .secondary) :global(.icon) {
  inline-size: 14px;
  block-size: 14px;
}

.btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}