/*------------------------------------*\
    TABLE OF CONTENTS
\*------------------------------------*/
/**
 *
 * FOUNDATIONS................................................
 * BASE.......................................................
 */
/*=============================================
 =                   Foundations               =
 =============================================*/
/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
/*==============================================
 =                   Base                      =
 =============================================*/
/*=============================================
=               CSS RESETS                    =
=============================================*/
/**
 *
 * The normalize.css npm package improves browser rendering consistency.
 * https://necolas.github.io/normalize.css/
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
figcaption,
figure {
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button; /* 2 */
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  color: inherit; /* 2 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE 9-.
 */
menu {
  display: block;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

:root {
  --dv-body-text-color: #1d1f22;
  --dv-body-bg-color: #fff;
  --dv-btn-primary-color: #fff;
  --dv-btn-primary-bg: #5ece7b;
  --dv-btn-primary-bg-hover: #9ee2b0;
  --dv-btn-secondary-color: #fff;
  --dv-btn-secondary-bg: #1d1f22;
  --dv-form-color: #1d1f22;
  --dv-form-bg: #fff;
  --dv-form-error-color: #d12727;
  --dv-form-error-border: #d12727;
  --dv-form-border-focus-color: #5ece7b;
  --primary-orange: #FAA31E;
  --secondary-orange: #FEEED6;
  --primary-red: #E05252;
  --secondary-red: #F8D9D9;
  --primary-green: #37B358;
  --secondary-green: #DBF1E1;
  --primary-blue: #2D6DB7;
  --secondary-blue: #F1F6FB;
  --primary-gray: #5F6A70;
  --secondary-gray: #DEDEDE;
  --hover-color: #F0F0F0;
  --dv-body-font-size: 1rem;
  --dv-body-font-weight: 400;
  --dv-h1-font-size: 1.375rem;
  --dv-h2-font-size: 1.375rem;
  --dv-h3-font-size: 1rem;
  --dv-h4-font-size: 1.125rem;
  --dv-h5-font-size: 0.75rem;
  --dv-h6-font-size: 0.75rem;
  --dv-h1-font-weight: 500;
  --dv-h2-font-weight: 500;
  --dv-h3-font-weight: 500;
  --dv-h4-font-weight: 400;
  --dv-h5-font-weight: 400;
  --dv-h6-font-weight: 300;
}

/* Poppins 100 - Thin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: local(""), url("../src/fonts/Poppins-Thin.ttf") format("truetype"), url("../src/fonts/Poppins-Thin.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Poppins 200 - ExtraLight */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: local(""), url("../src/fonts/Poppins-ExtraLight.ttf") format("truetype"), url("../src/fonts/Poppins-ExtraLight.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Poppins 300 - Light */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../src/fonts/Poppins-Light.ttf") format("truetype"), url("../src/fonts/Poppins-Light.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Poppins 400 - Regular */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../src/fonts/Poppins-Regular.ttf") format("truetype"), url("../src/fonts/Poppins-Regular.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Poppins 500 - Medium */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../src/fonts/Poppins-Medium.ttf") format("truetype"), url("../src/fonts/Poppins-Medium.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Poppins 600 - SemiBold */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../src/fonts/Poppins-SemiBold.ttf") format("truetype"), url("../src/fonts/Poppins-SemiBold.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Poppins 700 - Bold */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../src/fonts/Poppins-Bold.ttf") format("truetype"), url("../src/fonts/Poppins-Bold.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Poppins 800 - ExtraBold */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../src/fonts/Poppins-ExtraBold.ttf") format("truetype"), url("../src/fonts/Poppins-ExtraBold.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Poppins 900 - Black */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../src/fonts/Poppins-Black.ttf") format("truetype"), url("../src/fonts/Poppins-Black.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  min-block-size: 100%;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--dv-body-font-family, "Poppins", serif), -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  min-block-size: 100vh;
  font-size: 16px;
  text-rendering: optimizeSpeed;
}

/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.ta-center {
  text-align: center;
}

.ta-left {
  text-align: left;
}

.ta-right {
  text-align: right;
}

.flex {
  display: flex !important;
}

.fd-column {
  flex-direction: column;
}

.fd-row {
  flex-direction: row;
}

.jc-start {
  justify-content: flex-start !important;
}

.jc-center {
  justify-content: center;
}

.ai-start {
  align-items: flex-start !important;
}

.ai-center {
  align-items: center !important;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.no-wrap {
  white-space: nowrap;
}

.ws-wrap {
  white-space: pre-wrap !important;
}

.ta-center {
  text-align: center;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.pb-6 {
  padding-block: 6px;
}

.pbs-6 {
  padding-block-start: 6px;
}

.pbs-20 {
  padding-block-start: 20px;
}

.pbs-30 {
  padding-block-start: 30px;
}

.pbe-5 {
  padding-block-end: 6px;
}

.pbe-6 {
  padding-block-end: 6px;
}

.pi-5 {
  padding-inline: 5px;
}

.pie-5 {
  padding-inline-end: 5px;
}

.pie-10 {
  padding-inline-end: 10px;
}

.max-w-100 {
  max-width: 100px;
}

.validation-error-text {
  color: var(--dv-form-error-color, #d12727);
  font-size: 12px;
}

.validation-error-text.text-s {
  font-size: 11px;
}

.color_primary_red {
  color: var(--primary-red, #E05252);
}

.tags {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs, 8px);
}
.tags .tag {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs, 8px);
  font-family: Poppins;
  padding-block: var(--spacing-xxs, 4px);
  padding-inline: var(--spacing-s, 12px);
  background-color: var(--secondary-blue, #F1F6FB);
  border-radius: var(--radius-s, 12px);
  font-size: 9.5px;
  inline-size: fit-content;
  white-space: nowrap !important;
}
.tags .tag span {
  white-space: nowrap !important;
}
.tags .tag span.icon {
  inline-size: 15px;
  block-size: 15px;
}
.tags .tag.tag-xxs {
  font-size: 8px;
}
.tags .tag.tag-xs {
  font-size: 9px;
  padding-inline: 7px;
}
.tags .tag.tag-s {
  font-size: 10px;
}
.tags .tag.tag-m {
  font-size: 11px;
}
.tags .tag.tag-l {
  font-size: 12.5px;
}
.tags .tag.no_background {
  background-color: transparent !important;
}
.tags .tag.blue {
  background-color: var(--secondary-blue, #F1F6FB);
  color: var(--primary-blue, #2D6DB7);
}
.tags .tag.blue_secondary {
  background-color: var(--primary-blue, #2D6DB7);
  color: var(--secondary-blue, #F1F6FB);
}
.tags .tag.orange {
  background-color: var(--secondary-orange, #FEEED6);
  color: var(--primary-orange, #FAA31E);
  transition: 0.3s ease-in-out;
}
.tags .tag.orange.has_border {
  border: 0.5px solid var(--primary-orange, #FAA31E);
}
.tags .tag.orange.has_hover:hover {
  background-color: var(--primary-orange, #FAA31E);
  color: var(--secondary-orange, #FEEED6);
}
.tags .tag.orange_secondary {
  background-color: var(--primary-orange, #FAA31E);
  color: var(--secondary-orange, #FEEED6);
}
.tags .tag.green {
  background-color: var(--secondary-green, #DBF1E1);
  color: var(--primary-green, #37B358);
}
.tags .tag.green_secondary {
  background-color: var(--primary-green, #37B358);
  color: var(--secondary-green, #DBF1E1);
}
.tags .tag.red {
  background-color: var(--secondary-red, #F8D9D9);
  color: var(--primary-red, #E05252);
}
.tags .tag.red_secondary {
  background-color: var(--primary-red, #E05252);
  color: var(--secondary-red, #F8D9D9);
}

.cursor_help {
  cursor: help;
}

.cursor_pointer {
  cursor: pointer;
}

.fd-column {
  flex-direction: column;
}

.icon {
  display: flex;
  inline-size: 24px;
  block-size: 24px;
  color: currentColor;
}
.icon svg {
  inline-size: 100%;
  block-size: 100%;
}

.container {
  max-inline-size: var(--container-w);
  padding: 0 1rem;
  margin: 0 auto;
}

.fit-content {
  inline-size: fit-content !important;
}

.form-group {
  inline-size: 100%;
  position: relative;
  display: flex;
}
.form-group:not(:last-child) {
  margin-block-end: var(--spacing-l, 24px);
}
.form-group.fullWidth {
  flex: 1;
}

.tab_content {
  position: relative;
  z-index: 0;
  margin-block-start: -10px;
  padding-inline: var(--spacing-m, 16px);
  padding-block: var(--spacing-l, 24px);
  background-color: var(--color-white, #fff);
  border-radius: var(--radius-s, 12px);
  font-size: 14px;
  line-height: 17.5px;
}

.color_orange {
  color: var(--primary-orange, #FAA31E);
}

.bg_red {
  background-color: var(--secondary-red-light, #f8eded);
  border: 1px solid var(--primary-red, #E05252);
}
.bg_red * {
  border-color: var(--primary-red, #E05252) !important;
}

.color_red {
  color: var(--primary-red, #E05252) !important;
}

.bg_green {
  background-color: var(--secondary-green, #DBF1E1);
  border: 1px solid var(--primary-green, #37B358);
}
.bg_green * {
  border-color: var(--primary-green, #37B358) !important;
}

.color_green {
  color: var(--primary-green, #37B358) !important;
}

.bg_orange {
  background-color: var(--secondary-orange, #FEEED6);
  border: 1px solid var(--primary-orange, #FAA31E);
}
.bg_orange * {
  border-color: var(--primary-orange, #FAA31E) !important;
}

.color_orange {
  color: var(--primary-orange, #FAA31E) !important;
}

.d_none {
  display: none;
}

.d_block {
  display: block;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.bw-0-75 {
  border-style: solid;
  border-width: 0.75px;
}

.bw-5 {
  border-width: 5px;
}

.ant-popover-content {
  max-inline-size: 400px !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: var(--primary-green, #37B358) !important;
  border-color: var(--primary-green, #37B358) !important;
}

.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: var(--primary-green, #37B358) !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--color-white, #fff) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--primary-green, #37B358) !important;
  background-color: var(--primary-green, #37B358) !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: var(--primary-green, #37B358) !important;
  opacity: 0.3;
}
.ant-steps-item-wait .ant-steps-item-icon span {
  color: var(--color-white, #fff) !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--primary-green, #37B358) !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--primary-green, #37B358) !important;
}

.ant-steps-icon {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.modal_footer {
  display: flex;
  inline-size: 100%;
  gap: var(--spacing-l, 24px);
}
.modal_footer button {
  inline-size: 100%;
}

.ant-upload-list .ant-upload-list-item {
  font-family: Poppins;
  color: var(--color-white, #fff);
  border-radius: var(--radius-s, 12px);
  border-width: 1.5px;
  border-color: var(--color-white, #fff);
  padding-inline: var(--spacing-m, 16px);
  padding-block: var(--spacing-l, 24px);
}
.ant-upload-list .ant-upload-list-item * {
  color: var(--color-white, #fff);
}

.ant-upload-list.gray .ant-upload-list-item {
  color: var(--primary-gray, #5F6A70);
  border-color: var(--primary-gray, #5F6A70);
}
.ant-upload-list.gray .ant-upload-list-item * {
  color: var(--primary-gray, #5F6A70);
}

.ant-custom-button {
  font-family: Poppins;
  color: var(--primary-gray, #5F6A70);
  border-radius: var(--radius-s, 12px);
  padding-block-start: var(--spacing-s, 12px);
  padding-block-end: 35px;
  padding-inline: var(--spacing-m, 16px);
}

.ant-custom-button:hover {
  border-color: var(--primary-orange, #FAA31E);
  color: var(--primary-orange, #FAA31E);
}

.ant-notification {
  font-family: Poppins;
}

.ant-notification-notice-message {
  font-weight: 600;
}

.ant-table .ant-table-thead .ant-table-cell {
  background-color: var(--primary-gray, #5F6A70);
  color: var(--color-white, #fff);
  font-weight: 600;
}

.ant-table .ant-table-thead .ant-table-cell:first-child {
  border-top-left-radius: var(--radius-s, 12px);
}

.ant-table .ant-table-thead .ant-table-cell:last-child {
  border-top-right-radius: var(--radius-s, 12px);
}

.ant-table .ant-table-tbody .ant-table-row .ant-table-cell {
  color: var(--primary-gray, #5F6A70);
  font-weight: 500;
  font-size: 13px;
}

.ant-table .ant-table-tbody .ant-table-row:last-child .ant-table-cell:first-child {
  border-bottom-left-radius: var(--radius-s, 12px);
}
.ant-table .ant-table-tbody .ant-table-row:last-child .ant-table-cell:last-child {
  border-bottom-right-radius: var(--radius-s, 12px);
}

.ant-table .ant-table-tbody .ant-table-row .ant-table-cell {
  border-bottom: 1px solid var(--secondary-gray, #DEDEDE);
  border-top: 0;
}

.ant-table .ant-table-tbody .ant-table-row .ant-table-cell:first-child {
  border-left: 1px solid var(--secondary-gray, #DEDEDE);
}

.ant-table .ant-table-tbody .ant-table-row .ant-table-cell:last-child {
  border-right: 1px solid var(--secondary-gray, #DEDEDE);
}

.ant-table .ant-table-tbody:last-child {
  border-bottom-right-radius: var(--radius-s, 12px);
}

.ant-switch-checked {
  background: var(--primary-orange, #FAA31E) !important;
}

@media print {
  #printButton {
    display: none;
  }
  #printNextRow {
    display: flex;
  }
}
.no-gap {
  gap: 0 !important;
}

.border-top-none {
  border-top: none !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.orange_label_link {
  cursor: pointer;
  color: var(--primary-orange, #FAA31E);
  width: fit-content;
}

.orange_label_link:hover {
  text-decoration: underline;
}