/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}

.tab_pane_content {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: var(--spacing-xxs, 4px);
}
.tab_pane_content h5 {
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.tab_pane_content h4 {
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.user_section {
  display: flex;
  gap: var(--spacing-xxxl, 96px);
  justify-content: flex-start;
}

.user_section_form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: var(--spacing-l, 24px);
}

.close_warehouse {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-block: 42px;
}
.close_warehouse .option {
  display: flex;
  align-items: center;
  gap: var(--spacing-m, 16px);
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-gray, #5F6A70);
}

.warehouse_section {
  display: flex;
  gap: var(--spacing-xxl, 48px);
  inline-size: 80%;
}
.warehouse_section .block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;
  gap: var(--spacing-l, 24px);
  inline-size: 100%;
}

.addresses_section {
  display: flex;
  flex-direction: column;
  margin-block-start: var(--spacing-l, 24px);
  gap: var(--spacing-xl, 32px);
}
.addresses_section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--primary-gray, #5F6A70);
}
.addresses_section .addresses {
  display: flex;
  gap: var(--spacing-m, 16px);
  flex-wrap: wrap;
}
.addresses_section .addresses .card {
  display: flex;
  flex-direction: column;
  width: 204px;
  background: var(--color-white, #fff);
  border: 1px solid var(--secondary-gray, #DEDEDE);
  box-sizing: border-box;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding-inline-start: var(--spacing-m, 16px);
  padding-inline-end: var(--spacing-s, 12px);
  padding-block: var(--spacing-s, 12px);
}
.addresses_section .addresses .card .more_icon {
  display: flex;
  justify-content: flex-end;
}
.addresses_section .addresses .card .more_icon span {
  inline-size: 12px;
  block-size: 12px;
}
.addresses_section .addresses .card .title {
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-block-end: var(--spacing-m, 16px);
  color: var(--primary-gray, #5F6A70);
}
.addresses_section .addresses .card .address {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: var(--primary-gray, #5F6A70);
}
.addresses_section .addresses .card.selected {
  border: 1px solid var(--primary-green, #37B358);
}
.addresses_section .addresses .card.selected * {
  color: var(--primary-green, #37B358);
}
.addresses_section .addresses .card.selected .more_icon {
  display: flex;
  justify-content: flex-end;
}
.addresses_section .addresses .card.selected .more_icon span > svg > path {
  fill: var(--primary-green, #37B358);
}
.addresses_section .addresses .card_add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs, 8px);
  width: 204px;
  background: var(--color-white, #fff);
  border: 1px solid var(--secondary-gray, #DEDEDE);
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding-inline-start: var(--spacing-m, 16px);
  padding-inline-end: var(--spacing-s, 12px);
  padding-block: var(--spacing-s, 12px);
}
.addresses_section .addresses .card_add .add_icon span {
  inline-size: 42px;
  block-size: 42px;
}
.addresses_section .addresses .card_add .add_icon span > svg > path {
  fill: var(--primary-orange, #FAA31E);
}
.addresses_section .addresses .card_add .title {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  color: var(--primary-orange, #FAA31E);
}
.addresses_section .addresses .card_add:hover {
  transition: 1s;
  background-color: var(--primary-orange, #FAA31E);
  cursor: pointer;
}
.addresses_section .addresses .card_add:hover * {
  color: var(--secondary-orange, #FEEED6);
}
.addresses_section .addresses .card_add:hover .add_icon span > svg > path {
  fill: var(--secondary-orange, #FEEED6);
}
.addresses_section .addresses_group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}
.addresses_section .addresses_group .address {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s, 12px);
}
.addresses_section .addresses_group .address .title {
  font-size: 15px;
}
.addresses_section .addresses_group .address .content {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs, 8px);
}