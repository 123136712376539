/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.cargo_print {
  display: flex;
  flex-direction: column;
  font-family: Poppins;
}
.cargo_print table {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs, 8px);
  color: var(--primary-gray, #5F6A70);
}
.cargo_print table tr {
  border: 1.5px solid var(--primary-gray, #5F6A70);
  border-radius: 8px;
}
.cargo_print .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: var(--spacing-s, 12px);
  padding-block: var(--spacing-s, 12px);
}
.cargo_print .header h3, .cargo_print .header h4 {
  display: flex;
  gap: var(--spacing-s, 12px);
  align-items: center;
  padding: 0;
  margin: 0;
  color: var(--primary-gray, #5F6A70);
}
.cargo_print .header h3 b, .cargo_print .header h4 b {
  font-weight: 600;
  color: var(--color-black, #000);
}
.cargo_print .header h4 {
  font-weight: 600;
  color: var(--color-black, #000);
}
.cargo_print .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: var(--spacing-s, 12px);
  padding-block: var(--spacing-s, 12px);
}
.cargo_print .logo img {
  height: 50px;
}
.cargo_print .description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-xxxs, 2px);
  padding-inline: var(--spacing-xs, 8px);
  padding-block: var(--spacing-xs, 8px);
}
.cargo_print .description .row {
  display: flex;
  align-items: center;
}
.cargo_print .description .row .label {
  display: flex;
  width: fit-content;
  white-space: nowrap;
  align-items: center;
  flex: 4;
  font-size: 14px;
  color: var(--color-black, #000);
}
.cargo_print .description .row .text {
  display: flex;
  align-items: center;
  width: fit-content;
  flex: 10;
  font-size: 16px;
}
.cargo_print .integration_codes {
  display: flex;
  justify-content: space-around;
}
.cargo_print .integration_codes .integration_code {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-m, 16px);
  padding-inline: var(--spacing-s, 12px);
  padding-block: var(--spacing-s, 12px);
}
.cargo_print .integration_codes .integration_code h3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}
.cargo_print .order_description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-xxs, 4px);
  padding-inline: var(--spacing-s, 12px);
  padding-block: var(--spacing-s, 12px);
}
.cargo_print .order_description .row {
  display: flex;
  align-items: center;
}
.cargo_print .order_description .row .label {
  display: flex;
  align-items: center;
  flex: 4;
  font-size: 14px;
  color: var(--color-black, #000);
}
.cargo_print .order_description .row .text {
  display: flex;
  align-items: center;
  flex: 10;
  font-size: 16px;
}
.cargo_print .order_items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-xxs, 4px);
  padding-inline: var(--spacing-s, 12px);
  padding-block: var(--spacing-s, 12px);
}
.cargo_print .order_items .row {
  display: flex;
  align-items: center;
  padding-block: var(--spacing-xxs, 4px);
}
.cargo_print .order_items .row .item_image {
  padding: 5px;
  margin-inline-end: 10px;
  border: 0.5px solid var(--secondary-gray, #DEDEDE);
  border-radius: var(--radius-s, 12px);
}
.cargo_print .order_items .row .label {
  display: flex;
  align-items: center;
  flex: 4;
  font-size: 14px;
  color: var(--color-black, #000);
}
.cargo_print .order_items .row .text {
  display: flex;
  align-items: center;
  flex: 10;
  font-size: 16px;
}
.cargo_print .order_items .row.no_display {
  display: none;
}

.print_button {
  font-family: Poppins;
  padding-block: var(--spacing-s, 12px);
  padding-inline: var(--spacing-m, 16px);
  border: 1px solid var(--primary-orange, #FAA31E);
  background-color: var(--secondary-orange, #FEEED6);
  color: var(--primary-orange, #FAA31E);
  border-radius: var(--radius-s, 12px);
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.print_button:hover {
  font-family: Poppins;
  padding-block: var(--spacing-s, 12px);
  padding-inline: var(--spacing-m, 16px);
  border: 1px solid var(--secondary-orange, #FEEED6);
  background-color: var(--primary-orange, #FAA31E);
  color: var(--secondary-orange, #FEEED6);
  border-radius: var(--radius-s, 12px);
  cursor: pointer;
}

#printNextRow {
  display: none;
}