/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.order_item_container {
  display: flex;
  justify-content: space-between;
  font-family: Poppins;
}
.order_item_container .product_wrapper {
  display: flex;
}
.order_item_container .product_wrapper .image_block {
  padding-block: var(--spacing-s, 12px);
  padding-inline: var(--spacing-s, 12px);
  margin-block-end: var(--spacing-s, 12px);
  margin-inline-end: var(--spacing-s, 12px);
  border: 0.5px solid var(--secondary-gray, #DEDEDE);
  border-radius: var(--radius-s, 12px);
  height: fit-content;
}
.order_item_container .product_wrapper .image_block img {
  height: 100px;
  width: 100px;
}
.order_item_container .product_wrapper .product_block {
  display: flex;
  flex-direction: column;
}
.order_item_container .product_wrapper .product_block .block:first-child {
  padding-block-start: 0;
}
.order_item_container .product_wrapper .product_block .block:last-child {
  padding-block-end: 0;
}
.order_item_container .product_wrapper .product_block .block {
  padding-block: var(--spacing-xxs, 4px);
  line-height: 18px;
}
.order_item_container .product_wrapper .product_block .block .title {
  font-size: 12px;
  font-weight: 700;
}
.order_item_container .product_wrapper .product_block .block .description {
  font-weight: 500;
  color: var(--primary-gray, #5F6A70);
}
.order_item_container .order_wrapper .order_detail_block {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.order_item_container .order_wrapper .order_detail_block .block:first-child {
  padding-block-start: 0;
}
.order_item_container .order_wrapper .order_detail_block .block:last-child {
  padding-block-end: 0;
}
.order_item_container .order_wrapper .order_detail_block .block {
  padding-block: var(--spacing-xxs, 4px);
  line-height: 19px;
}
.order_item_container .order_wrapper .order_detail_block .block .title {
  font-size: 12px;
  font-weight: 700;
}
.order_item_container .order_wrapper .order_detail_block .block .description {
  font-weight: 500;
  color: var(--primary-gray, #5F6A70);
  font-size: 17px;
}
.order_item_container .order_wrapper .order_detail_block hr {
  border: 1px solid var(--secondary-gray, #DEDEDE);
  border-top-left-radius: var(--radius-s, 12px);
  border-bottom-left-radius: var(--radius-s, 12px);
  width: 100%;
}