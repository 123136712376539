/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.checkbox_container {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  user-select: none;
  cursor: pointer;
  gap: 0.5rem;
  font-size: 14px;
  border-bottom: 1px solid var(--text-tertiary);
  color: var(--text-900);
}
@media screen and (hover: hover) and (pointer: fine) {
  .checkbox_container:hover .checkbox_checkmark {
    background-color: var(--text-tertiary);
  }
}
.checkbox_container--agreement {
  display: flex;
  align-items: center;
  border-bottom: 0;
}
.checkbox_container--agreement .checkbox_checkmark {
  position: absolute;
  left: 0;
  border-radius: var(--radius-xs) !important;
  border-color: rgba(48, 0, 62, 0.5) !important;
}
.checkbox_container--agreement > span {
  color: var(--text-secondary) !important;
  font-size: 14px !important;
}
.checkbox_container input {
  position: absolute;
  inline-size: 0;
  block-size: 0;
  opacity: 0;
  cursor: pointer;
}
.checkbox_container input:checked ~ .checkbox_checkmark {
  background-color: var(--primary-900);
}
.checkbox_container input:checked ~ .checkbox_checkmark .checkbox_checkmark--icon {
  opacity: 1;
  visibility: visible;
}
.checkbox_container span {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  color: inherit;
}
.checkbox_container .checkbox_checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: auto;
  block-size: auto;
  background-color: transparent;
  border: 1px solid var(--text-tertiary);
  border-radius: var(--radius-l);
  transition: 150ms background-color;
}
.checkbox_container .checkbox_checkmark--icon {
  display: flex;
  inline-size: auto;
  block-size: auto;
  transition: 150ms opacity, 150ms visibility;
}
.checkbox_container .checkbox_checkmark--icon :global(.icon) {
  inline-size: auto;
  block-size: auto;
  color: #fff;
}
.checkbox_container .checkbox_checkmark.primary {
  background-color: var(--color-white, #fff);
}