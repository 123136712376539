/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.input_group {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.input_group label {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs, 4px);
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  color: var(--primary-gray, #5F6A70);
}
.input_group .input_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--secondary-gray, #DEDEDE);
  border-radius: var(--radius-s, 12px);
  padding-inline: var(--spacing-s, 12px);
}
.input_group .input_section .update_button {
  position: relative;
  color: var(--primary-orange, #FAA31E);
}
.input_group .input_section .update_button:hover {
  cursor: pointer;
  text-decoration-line: underline;
}
.input_group input {
  padding-block: var(--spacing-m, 16px);
  padding-inline-end: var(--spacing-s, 12px);
  outline: none;
  border: none;
  background-color: transparent;
  inline-size: 100%;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-gray, #5F6A70);
}
.input_group input::placeholder {
  font-family: "Poppins";
  font-weight: 400;
  color: var(--secondary-gray, #DEDEDE);
}
.input_group input[type=file] {
  inline-size: fit-content;
}
.input_group .input_section.highlighted {
  box-shadow: 0 0 0 2px var(--primary-orange, #FAA31E);
}
.input_group .input_section.file input {
  color: var(--color-white, #fff);
}
.input_group .input_section.disabled {
  background: #dddddd;
}
.input_group .input_section:hover {
  transition: 0.3s ease-in-out;
  border-color: var(--primary-orange, #FAA31E);
  box-shadow: 0 0 0 1px var(--primary-orange, #FAA31E);
}
.input_group .input_section input:focus {
  transition: 0.3s ease-in-out;
  color: var(--primary-orange, #FAA31E);
  font-weight: 600;
}
.input_group .input_section:has(input:focus) {
  transition: 0.3s ease-in-out;
  color: var(--primary-orange, #FAA31E);
  font-weight: 600;
}
.input_group .input_section.no_indicator input::-webkit-outer-spin-button,
.input_group .input_section.no_indicator input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}
.input_group input:focus ~ .icon_wrapper span > svg > path {
  transition: 0.3s ease-in-out;
  stroke: var(--primary-orange, #FAA31E);
  fill: var(--primary-orange, #FAA31E);
}
.input_group .icon_wrapper {
  display: flex;
  position: relative;
}
.input_group textarea {
  padding-block: var(--spacing-m, 16px);
  padding-inline-end: var(--spacing-s, 12px);
  outline: none;
  border: none;
  inline-size: 100%;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-gray, #5F6A70);
}
.input_group textarea::placeholder {
  font-family: "Poppins";
  font-weight: 400;
  color: var(--secondary-gray, #DEDEDE);
}
.input_group .input_section:hover {
  transition: 0.3s ease-in-out;
  border-color: var(--primary-orange, #FAA31E);
  box-shadow: 0 0 0 1px var(--primary-orange, #FAA31E);
}
.input_group .input_section textarea:focus {
  transition: 0.3s ease-in-out;
  color: var(--primary-orange, #FAA31E);
  font-weight: 600;
}
.input_group .input_section:has(textarea:focus) {
  transition: 0.3s ease-in-out;
  color: var(--primary-orange, #FAA31E);
  font-weight: 600;
}

.input_group.full_width {
  inline-size: 100%;
}

.toggle_password_view {
  color: #666;
  width: 32px;
  height: 32px;
  outline: 0;
  background-color: transparent;
  border: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23666'%3E%3Cpath d='M10 12a2 2 0 100-4 2 2 0 000 4z' /%3E%3Cpath fill-rule='evenodd' d='M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;
  transition: cursor 0.3s;
}
@media screen and (hover: hover) and (pointer: fine) {
  .toggle_password_view:hover {
    cursor: pointer;
  }
}
.toggle_password_view.is-visible {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23666'%3E%3Cpath fill-rule='evenodd' d='M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z' clip-rule='evenodd' /%3E%3Cpath d='M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z' /%3E%3C/svg%3E");
}