/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}
.container .block_with_nav {
  display: flex;
  gap: var(--spacing-l, 24px);
}
.container .block_with_nav .block {
  display: flex;
  flex-direction: column;
  flex: 11;
}
.container .block_with_nav .block p {
  margin: 0;
}
.container .block_with_nav .block .form {
  display: flex;
  flex-direction: column;
  gap: 44px;
}
.container .block_with_nav .block .form .input_group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
}
.container .block_with_nav .block .form .input_group_block {
  display: flex;
  inline-size: 100%;
  align-items: flex-end;
  gap: var(--spacing-xl, 32px);
}
.container .block_with_nav .block .form .input_group_block .delete_icon {
  padding-block-end: 5px;
}
.container .block_with_nav .block .form .input_group_block .delete_icon :global(.icon) {
  inline-size: 38px;
  block-size: auto;
}
.container .block_with_nav .block .form .input_group_block .element {
  display: flex;
  inline-size: 100%;
}
.container .block_with_nav .block .form .input_group_block .element.flex_end {
  justify-content: flex-end;
}
.container .block_with_nav .block .form .input_group_block.w80 {
  inline-size: 80%;
}
.container .block_with_nav .block .form .input_group_block.w50 {
  inline-size: 50%;
}
.container .block_with_nav .block .form .input_group .suggestion_cards {
  display: flex;
  gap: var(--spacing-l, 24px);
  flex-wrap: wrap;
}
.container .block_with_nav .block .form .input_group .suggestion_cards .suggestion_card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: var(--spacing-l, 24px);
  padding-inline: var(--spacing-xl, 32px);
  border: 1px solid var(--secondary-gray, #DEDEDE);
  border-radius: var(--radius-s, 12px);
  gap: var(--spacing-l, 24px);
  inline-size: 280px;
}
.container .block_with_nav .block .form .input_group .suggestion_cards .suggestion_card :global(.icon) {
  padding-block: 10px;
  inline-size: 75px;
  block-size: 75px;
}
.container .block_with_nav .block .form .input_group .suggestion_cards .suggestion_card .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.container .block_with_nav .block .form .input_group .suggestion_cards .suggestion_card .content h4 {
  color: var(--primary-gray, #5F6A70);
  font-size: 16px;
  line-height: 130%;
}
.container .block_with_nav .block .form .input_group .suggestion_cards .suggestion_card.selected {
  transition: 0.4s ease-in-out;
  border-color: var(--primary-orange, #FAA31E);
  box-shadow: 0 0 0 1.5px var(--primary-orange, #FAA31E);
}
.container .block_with_nav .block .form .actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-l, 24px);
}
.container .block_with_nav .nav {
  flex: 4;
}
.container .block_with_nav .nav_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-l, 24px);
  text-align: center;
  padding-inline: 44px;
  padding-block: var(--spacing-s, 12px);
}
.container .block_with_nav .nav h5 {
  font-size: 18px;
}
.container .block_with_nav .nav p {
  font-size: 14px;
}

.variant_table_name_cell {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxs, 2px);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}
.variant_table_name_cell .description {
  color: var(--secondary-gray, #DEDEDE);
}