/*=============================================
    #Variables
=============================================*/
/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */
/*=============================================
=                  Colors                     =
=============================================*/
/* Brand Colors */
/* Neutral Colors */
/* Utility colors */
/*=====  End of Colors  ======*/
/*=============================================
=                Typography                   =
=============================================*/
/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Playfair+Display
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */
/* Font families */
/* 2 */
/* 2 */
/* 3 */
/* Font sizes */
/* Heading font sizes */
/* Font weights */
/*=====  End of Typography  ======*/
/*=============================================
=            Breakpoints                      =
=============================================*/
/*=====  End of Breakpoints  ======*/
/*=============================================
=            Spacing                          =
=============================================*/
/*=====  End of Spacing  ======*/
/*=============================================
=            Foundation - colors              =
=============================================*/
/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */
/*=============================================
=            Global text colors               =
=============================================*/
/*=============================================
=                 Buttons                     =
=============================================*/
/*=============================================
=                 Forms                       =
=============================================*/
/*=============================================
=        Foundation - Typography              =
=============================================*/
/**
 * This file defines the default font sizes to be used on headings and texts. The defaults come
 * from the palette defined in _variables.scss.
 */
/*=============================================
=            Body font family                  =
=============================================*/
/*=============================================
=            Body font sizes                  =
=============================================*/
/*=============================================
=            Heading font sizes               =
=============================================*/
/*=============================================
=            Head font weights                =
=============================================*/
/*=============================================
=            Mixins                           =
=============================================*/
/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */
.container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-l, 24px);
}
.container .section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl, 32px);
}
.container .section .block {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
}
.container .section .cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  gap: var(--spacing-l, 24px);
}
.container .section .cards :global(.default_card) {
  block-size: 200px;
}
.container .section .cards :global(.default_card) h5 {
  display: flex;
  align-items: center;
}
.container .section .cards img {
  margin-block-end: 10px;
}
.container .section .cards h1, .container .section .cards h2, .container .section .cards h3, .container .section .cards h4, .container .section .cards h5, .container .section .cards h6 {
  display: flex;
  align-items: center;
}
.container .section .cards h3 {
  font-weight: 600;
}
.container .section .cards h3.amount {
  margin-block-end: 20px;
}
.container .section .cards h5 {
  font-weight: 600;
  gap: var(--spacing-xxs, 4px);
  color: var(--primary-gray, #5F6A70);
}
.container .section .cards h5 :global(.icon) {
  inline-size: 18px;
  block-size: 18px;
}
.container .section .cards .container_with_icon {
  display: flex;
  flex-direction: column;
}
.container .section .cards .container_with_icon :global(.default_card) {
  display: flex;
  align-items: center;
  min-block-size: auto;
}
.container .section .cards .container_with_icon_block {
  display: flex;
  align-items: center;
  gap: var(--spacing-s, 12px);
}
.container .section .cards .container_with_icon_block img {
  margin-block-end: 0;
}
.container .section .cards .container_with_icon_block .icon .rating {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 66px;
  color: var(--color-white, #fff);
  font-weight: 600;
  font-size: 16px;
  background-color: var(--primary-green, #37B358);
  border-radius: 50%;
}
.container .section .cards .container_with_icon_block .container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs, 8px);
}
.container .section .cards .container_with_icon_block .container .questions {
  display: flex;
  gap: var(--spacing-m, 16px);
  align-items: center;
}
.container .section .cards .container_with_icon_block .container .questions span {
  color: var(--primary-gray, #5F6A70);
  font-size: 14px;
  margin-bottom: 0;
}
.container .section .cards .container_with_icon_block .container p {
  color: var(--primary-gray, #5F6A70);
  font-size: 14px;
  margin-bottom: 0;
}
.container .section .cards .container_with_icon_block .container h5 {
  font-weight: 600;
  gap: var(--spacing-xxs, 4px);
  color: var(--primary-gray, #5F6A70);
  padding: 0;
  margin: 0;
}
.container .section .cards .container_with_icon_block .container h5 :global(.icon) {
  inline-size: 18px;
  block-size: 18px;
}
.container .section .cards .requests {
  display: flex;
  flex-direction: column;
}
.container .section .cards .requests :global(.default_card) {
  display: flex;
  flex-direction: column;
  block-size: 489px;
}
.container .section .cards .requests_block {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
}
.container .section .cards .requests_block_container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l, 24px);
}
.container .section .cards .requests_block_container .request {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs, 8px);
}
.container .section .cards .requests_block_container .request p {
  color: var(--primary-gray, #5F6A70);
  font-size: 14px;
  margin-bottom: 0;
}
.container .section .cards .requests_block_container .request h5 {
  color: var(--primary-orange, #FAA31E);
  font-weight: 600;
  gap: var(--spacing-xxs, 4px);
  padding: 0;
  margin: 0;
}
.container .section .cards .requests_block_container .request h5 :global(.icon) {
  inline-size: 18px;
  block-size: 18px;
}
.container .section .cards .requests_block_container .request span.date {
  color: var(--primary-gray, #5F6A70);
  font-size: 12px;
  margin-bottom: 0;
}
.container .section .cards.column {
  display: flex;
  flex-direction: column;
}
.container .section .big_card {
  display: flex;
  flex-direction: column;
}
.container .section .big_card :global(.default_card) {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s, 12px);
  block-size: 425px;
}
.container .section .big_card h5 {
  font-weight: 600;
  gap: var(--spacing-xxs, 4px);
  color: var(--primary-gray, #5F6A70);
}
.container .section .big_card h5 :global(.icon) {
  inline-size: 18px;
  block-size: 18px;
}
.container .section .big_card .card_input {
  display: flex;
  align-items: center;
  gap: var(--spacing-m, 16px);
}
.container .section .big_card .card_input button,
.container .section .big_card .card_input input {
  block-size: 40px;
  margin: 0;
}
.container .section .big_card .card_input button {
  inline-size: 20px;
}
.container .section .big_card .card_input h5 {
  padding: 0;
  margin: 0;
}
.container .section .big_card .checkboxes {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s, 12px);
}
.container .section .metrics {
  display: flex;
}
.container .section .metrics_block {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.container .section .metrics_block h1, .container .section .metrics_block h2, .container .section .metrics_block h3, .container .section .metrics_block h4, .container .section .metrics_block h5, .container .section .metrics_block h6 {
  display: flex;
  align-items: center;
}
.container .section .metrics_block h3 {
  font-weight: 600;
  margin-block-end: var(--spacing-l, 24px);
}
.container .section .metrics_block h3.red {
  color: var(--primary-red, #E05252);
}
.container .section .metrics_block h5 {
  font-weight: 600;
  gap: var(--spacing-xxs, 4px);
  color: var(--primary-gray, #5F6A70);
}
.container .section .metrics_block h5 :global(.icon) {
  inline-size: 18px;
  block-size: 18px;
}

.stocks {
  display: flex;
  flex-direction: column;
}
.stocks :global(.default_card) {
  display: flex;
  flex-direction: column;
  min-block-size: 489px;
}
.stocks_block {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m, 16px);
  overflow-y: auto;
}
.stocks_block .title {
  display: flex;
  gap: 10px;
  align-items: center;
}
.stocks_block_container {
  display: flex;
  gap: var(--spacing-s, 12px);
  color: var(--primary-gray, #5F6A70);
}
.stocks_block_container .img img {
  border-radius: var(--radius-xxs, 4px);
  padding: 2px;
  border: 1px solid var(--secondary-gray, #DEDEDE);
}
.stocks_block_container .content .name {
  font-weight: 600;
}
.stocks_block_container .content .description {
  display: flex;
  align-items: center;
}
.stocks_block_container .content .description .price {
  font-size: 14px;
  padding-inline-start: 5px;
}
.stocks_block_container .content .description .amount {
  font-size: 16px;
  padding-inline-end: 5px;
}
.stocks_block_container .end_line {
  padding-block-start: 1px;
}
.stocks_block_container .stock {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs, 8px);
}
.stocks_block_container .stock p {
  color: var(--primary-gray, #5F6A70);
  font-size: 14px;
  margin-bottom: 0;
}
.stocks_block_container .stock h5 {
  color: var(--primary-orange, #FAA31E);
  font-weight: 600;
  gap: var(--spacing-xxs, 4px);
  padding: 0;
  margin: 0;
}
.stocks_block_container .stock h5 :global(.icon) {
  inline-size: 18px;
  block-size: 18px;
}
.stocks_block_container .stock span.date {
  color: var(--primary-gray, #5F6A70);
  font-size: 12px;
  margin-bottom: 0;
}